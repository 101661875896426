import React from "react";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  // GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
  GridCellProps,
} from "@progress/kendo-react-grid";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import FormDatePicker from "../../components/formFields/FormDateField";
import ButtonForAll from "../../components/common/Button";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  HeaderThElement,
  // PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import moment from "moment";
import { useLocation } from "react-router-dom";
import FormTextField from "../../components/formFields/FormTextField";
import { openDialog } from "../../components/dialog/dialogSlice";
import { setItemHSNCodeGUID } from "./itemSlice";
import { MdDelete } from "react-icons/md";

// interface PageState {
//   skip: number;
//   take: number;
// }

// const initialDataState: PageState = { skip: 0, take: 50 };

const HSNcodeDetailsArray = ({
  formRenderProps,
  setSubmitAction,
  showHSNcode,
  setShowHSNcode,
}: {
  formRenderProps: FormRenderProps;
  setSubmitAction: any;
  showHSNcode: boolean;
  setShowHSNcode: any;
}) => {
  const location = useLocation();
  const item_guid = location.state?.item_guid;
  const ItemDetail = useAppSelector((state) => state.item.ItemDetail);
  const dispatch = useAppDispatch();
  // const [mrpPage, setMRPPage] = useState<PageState>(initialDataState);
  // const [ratePage, setRatePage] = useState<PageState>(initialDataState);
  // const [mrpPageSizeValue, setMRPPageSizeValue] = useState<
  //   number | string | undefined
  // >();
  // const [ratePageSizeValue, setRatePageSizeValue] = useState<
  //   number | string | undefined
  // >();

  // const pageChangeForMRP = (event: GridPageChangeEvent) => {
  //   const targetEvent = event.targetEvent as PagerTargetEvent;
  //   const take =
  //     targetEvent.value === "All"
  //       ? ItemDetail?.item_hsn_sac_code?.length || event.page.take
  //       : event.page.take;

  //   if (targetEvent.value) {
  //     setMRPPageSizeValue(targetEvent.value);
  //   }
  //   setMRPPage({
  //     ...event.page,
  //     take,
  //   });
  // };

  // const pageChangeForRate = (event: GridPageChangeEvent) => {
  //   const targetEvent = event.targetEvent as PagerTargetEvent;
  //   const take =
  //     targetEvent.value === "All"
  //       ? ItemDetail?.item_margin_rate?.length || event.page.take
  //       : event.page.take;

  //   if (targetEvent.value) {
  //     setRatePageSizeValue(targetEvent.value);
  //   }
  //   setRatePage({
  //     ...event.page,
  //     take,
  //   });
  // };

  const handleClearHSNcode = (formRenderProps: FormRenderProps) => {
    formRenderProps.onChange("item_hsn_sac_code_guid", {
      value: null,
    });
    formRenderProps.onChange("from_start_date", {
      value: "",
    });
    formRenderProps.onChange("hsn_Sac_code", {
      value: "",
    });
    setShowHSNcode(!showHSNcode);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 12 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyEditHSNcodeCommandCell = (
    props: GridCellProps,
    formRenderProps: FormRenderProps
  ) => {
    const handleEdit = (item: any) => {
      formRenderProps.onChange("item_hsn_sac_code_guid", {
        value: item?.item_hsn_sac_code_guid,
      });
      formRenderProps.onChange("from_start_date", {
        value: item?.from_date
          ? moment(item?.from_date, "DD/MM/YYYY").toDate()
          : "",
      });
      formRenderProps.onChange("from_end_date", {
        value: item?.to_date
          ? moment(item?.to_date, "DD/MM/YYYY").toDate()
          : "",
      });
      formRenderProps.onChange("hsn_Sac_code", {
        value: item?.hsn_Sac_code || 0,
      });
    };

    const handleOpenDeleteDialog = (ID: string) => {
      dispatch(openDialog("deleteItemHSNcode"));
      dispatch(setItemHSNCodeGUID(ID));
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() =>
            handleOpenDeleteDialog(props.dataItem?.item_hsn_sac_code_guid)
          }
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  return (
    <>
      {((ItemDetail?.item_hsn_sac_code &&
        ItemDetail?.item_hsn_sac_code?.length < 1) ||
        showHSNcode ||
        formRenderProps.valueGetter("from_start_date")) &&
        item_guid && (
          <Card
            style={{
              padding: 10,
              border: "1px solid goldenrod",
              marginBottom: 30,
            }}
          >
            <GridLayout cols={[{ width: "100%" }]}>
              <GridLayoutItem>
                <Typography.h4 className="text-dark">
                  {"HSN/SAC Code Details"}
                </Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem
                className="bg-dark text-white fw-600"
                style={{ width: "100%" }}
              >
                <GridLayout
                  style={{
                    marginRight: 20,
                    padding: "10px 0",
                  }}
                  gap={{ rows: 0, cols: "0.5%" }}
                  cols={[{ width: "40%" }, { width: "40%" }, { width: "18%" }]}
                >
                  <GridLayoutItem className="ps-2">From date</GridLayoutItem>
                  <GridLayoutItem className="ps-2">HSN/SAC Code</GridLayoutItem>
                  <GridLayoutItem className="ps-2">Actions</GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
              <GridLayoutItem>
                <GridLayout
                  gap={{ rows: 0, cols: "0.5%" }}
                  cols={[{ width: "40%" }, { width: "40%" }, { width: "18%" }]}
                >
                  <GridLayoutItem>
                    <Field
                      name="from_start_date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-100 right-alighned-field"
                      name={`hsn_Sac_code`}
                      placeholder="i.e.09042110"
                      component={FormTextField}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem style={{ textAlign: "end", marginTop: 16 }}>
                    <ButtonForAll
                      label={
                        formRenderProps.valueGetter("item_hsn_sac_code_guid")
                          ? "Update"
                          : "Add"
                      }
                      disabled={
                        formRenderProps.valueGetter("from_start_date") &&
                        formRenderProps.valueGetter("hsn_Sac_code")
                          ? false
                          : true
                      }
                      type="submit"
                      onClick={() => setSubmitAction("hsncode")}
                    />
                    <Button
                      style={{ marginLeft: 4 }}
                      fillMode={"outline"}
                      themeColor={"primary"}
                      type="button"
                      onClick={() => {
                        handleClearHSNcode(formRenderProps);
                      }}
                    >
                      Clear
                    </Button>
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            </GridLayout>
          </Card>
        )}
      {ItemDetail &&
        ItemDetail?.item_hsn_sac_code &&
        ItemDetail?.item_hsn_sac_code?.length > 0 && (
          <>
            <GridLayoutItem
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography.h4 className="m-0 text-dark">
                {"HSN/SAC Code List"}
              </Typography.h4>
              <ButtonForAll
                label={showHSNcode ? "Hide" : "Add"}
                type="button"
                onClick={() => setShowHSNcode(!showHSNcode)}
              />
            </GridLayoutItem>
            <KendoGrid
              style={{ marginTop: 14 }}
              data={
                ItemDetail?.item_hsn_sac_code
                // ? ItemDetail?.item_hsn_sac_code?.slice(
                //     mrpPage.skip,
                //     mrpPage.take + mrpPage.skip
                //   )
                // : []
              }
              // skip={mrpPage.skip}
              // take={mrpPage.take}
              total={ItemDetail?.item_hsn_sac_code?.length}
              // pageable={{
              //   buttonCount: 5,
              //   pageSizes: [10, 25, 50, "All"],
              //   pageSizeValue: mrpPageSizeValue,
              //   type: "input",

              // }}
              pageable={false}
              // onPageChange={pageChangeForMRP}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <Column field="from_date" title="From Date" />
              <Column field="to_date" title="To Date" />
              <Column field="hsn_Sac_code" title="HSN/SAC Code" />
              <Column
                field="ID"
                title="Actions"
                cell={(props: any) =>
                  MyEditHSNcodeCommandCell(props, formRenderProps)
                }
                width={"110px"}
                filterable={false}
                locked={true}
              />
            </KendoGrid>
          </>
        )}
    </>
  );
};

export default HSNcodeDetailsArray;
