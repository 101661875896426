import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { LoadingPanel } from "../../components/layout/Loading";
import { getAllOrdersByUserID } from "./services/order.services";
import {
  TreeList,
  filterBy,
  extendDataItem,
  mapTree,
  TreeListTextFilter,
  TreeListDateFilter,
  TreeListNumericFilter,
  TreeListCellProps,
} from "@progress/kendo-react-treelist";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Pager, PagerProps } from "@progress/kendo-react-data-tools";

const OrderHistory: React.FC = () => {
  const dispatch = useAppDispatch();
  const AccountIDString = useAppSelector(
    (state) => state.login.loginDetails.AccountID
  );
  const UserIDString = useAppSelector(
    (state) => state.login.loginDetails.UserID
  );
  const UserTypeIDString = useAppSelector(
    (state) => state.login.loginDetails.UserTypeID
  );
  const AccountID = parseInt(AccountIDString);
  const UserID = parseInt(UserIDString);
  const UserTypeID = parseInt(UserTypeIDString);

  useEffect(() => {
    if (AccountID || UserID) {
      const payload = {
        id: UserTypeID === 5 ? UserID : AccountID ? AccountID : null,
        isemployee: UserTypeID === 5 ? true : false,
      };
      dispatch(getAllOrdersByUserID(payload));
    }
  }, [AccountID]);

  return (
    <>
      <GridLayout
        cols={[{ width: "100%" }]}
        className="container"
        style={{ minWidth: 800 }}
      >
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 20px 14px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Order History
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <OrderHistoryGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const OrderHistoryGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();

  const OrderList = useAppSelector((state) => state.order.OrderList);
  const loading = useAppSelector((state) => state.order.loading);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >(10);
  const [state, setState] = React.useState({
    data: [...OrderList],
    filter: [],
    expanded: [1, 2, 32],
  });

  useEffect(() => {
    setState({
      data: [...OrderList],
      filter: [],
      expanded: [1, 2, 32],
    });
  }, [OrderList]);

  const pageChange = (event: any) => {
    const targetEvent = event.targetEvent as any;
    const take = targetEvent.value === "All" ? OrderList.length : event.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      skip: event.skip,
      take,
    });
  };

  const TreeListPager = (props: PagerProps) => {
    return (
      <Pager
        {...props}
        previousNext={true}
        buttonCount={8}
        pageSizeValue={pageSizeValue}
        pageSizes={[5, 10, 50, 100, 500]}
        onPageChange={pageChange}
      />
    );
  };

  const MyEditCommandCell = (props: TreeListCellProps) => {
    const handleEdit = (id: any) => {
      navigate("/order/edit", { state: { id: id } });
    };
    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {((props?.level?.length < 2 && props?.hasChildren === false) ||
          (props?.level?.length > 1 && props?.level[1] === 0)) && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEdit(props.dataItem.id)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const subItemsField = "place_order_child";
  const expandField = "expanded";
  const columns = [
    {
      field: "sales_order_no",
      title: "Order No",
      width: "320px",
      filter: TreeListTextFilter,
      expandable: true,
    },
    {
      field: "sales_date",
      title: "Sales Date",
      width: "280px",
      format: "{0:d}",
      filter: TreeListDateFilter,
    },
    {
      field: "total_amount",
      title: "Total Amount",
      width: "280px",
      filter: TreeListNumericFilter,
    },
    {
      field: "order_no",
      title: "Action",
      width: "190px",
      cell: MyEditCommandCell,
    },
  ];

  const onExpandChange = (e: any) => {
    setState({
      ...state,
      expanded: e.value
        ? state.expanded.filter((id) => id !== e.dataItem.id)
        : [...state.expanded, e.dataItem.id],
    });
  };
  const handleFilterChange = (event: any) => {
    setPage({ skip: 0, take: page.take });
    setState({
      ...state,
      filter: event.filter,
    });
  };
  const addExpandField = (dataTree: any) => {
    const expanded = state.expanded;
    return mapTree(dataTree, subItemsField, (item) =>
      extendDataItem(item, subItemsField, {
        [expandField]: expanded.includes(item.id),
      })
    );
  };
  const processData = () => {
    let data = state.data;
    let filteredData = filterBy(data, state?.filter, subItemsField);
    return addExpandField(filteredData);
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <TreeList
        pager={TreeListPager}
        style={{
          maxHeight: "510px",
          overflow: "auto",
        }}
        expandField={expandField}
        subItemsField={subItemsField}
        skip={page?.skip}
        take={page?.take}
        onExpandChange={onExpandChange}
        filter={state?.filter}
        data={processData()}
        onFilterChange={handleFilterChange}
        columns={columns}
      />
    </>
  );
};

export default OrderHistory;
