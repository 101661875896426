import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import {
  requiredValidator,
  skipValidator,
} from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormDatePicker from "../../components/formFields/FormDateField";
import {
  getAccountByID,
  getAllAccountIncremental,
} from "../account/services/account.services";
import { getAllItemIncremental } from "../Item/services/item.services";
import { findAllActiveUnit } from "../unit/services/unit.services";
import ButtonForAll from "../../components/common/Button";
import { clearItemList } from "../Item/itemSlice";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import {
  clearSalesChallanDetails,
  setSalesChallanStateForGST,
} from "./salesChallanSlice";
import {
  getAllLimitedSalesOrders,
  getSalesOrderByID,
} from "../salesorder/services/salesOrder.services";
import { getAllActiveGST } from "../gst/services/gst.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { FINANCIAL_YEAR, GUJARATIDFORGST } from "../../_contstants/common";
import moment from "moment";
import SCItemDetailsArray from "./SCItemDetailsArray";
import {
  createSalesChallan,
  generateChallanNoForSalesChallan,
  getProductionLotNoByItemID,
  getProductionLotNoCurrentStock,
  getSalesChallanByID,
  updateSalesChallan,
} from "./services/salesChallan.services";
import { clearSalesOrderDetails } from "../salesorder/salesOrderSlice";
import { getAllActiveRoute } from "../route/services/route.services";
import { IRoute } from "../route/routeModel";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import { formatIndianNumber } from "../../_helper/helper";
import FormTextArea from "../../components/formFields/FormTextArea";
import { IScheme } from "../scheme/schemeModel";
import { getAllSchemes } from "../scheme/services/scheme.services";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";
import { IVoucherType } from "../vouchertype/voucherTypeModel";
import { getAllVoucherTypes } from "../vouchertype/services/voucherType.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const QtyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const sales_challan_items = formRenderProps.valueGetter(
    "sales_challan_items"
  );
  const taxable_amount = formRenderProps.valueGetter("taxable_amount");
  const gst = formRenderProps.valueGetter("gst");
  const sub_total = formRenderProps.valueGetter("sub_total");
  const round_off = formRenderProps.valueGetter("round_off");
  const SalesChallanStateForGST = useAppSelector(
    (state) => state.salesChallan.SalesChallanStateForGST
  );
  const UnitList = useAppSelector((state) => state.unit.UnitList);

  useEffect(() => {
    let totalGSTamount = 0;
    let totalGST5 = 0;
    let totalGST12 = 0;
    let totalGST18 = 0;
    let totalGST28 = 0;

    sales_challan_items?.map((item: any, index: number) => {
      const gst_id = item?.gst_id;
      const amount = item?.amount;
      const gstid = gst_id ? parseInt(gst_id?.split("--")[1]) : 0;
      if (gstid === 5) {
        totalGST5 += (+amount * +gstid) / 100;
      } else if (gstid === 12) {
        totalGST12 += (+amount * +gstid) / 100;
      } else if (gstid === 18) {
        totalGST18 += (+amount * +gstid) / 100;
      } else if (gstid === 28) {
        totalGST28 += (+amount * +gstid) / 100;
      }
      totalGSTamount += (+amount * +gstid) / 100;
      if (!gst_id) {
        formRenderProps.onChange(`sales_challan_items.${index}.gst_id`, {
          value: null,
        });
      }
    });
    formRenderProps.onChange("gst", {
      value: totalGSTamount || 0,
    });
    if (SalesChallanStateForGST === GUJARATIDFORGST) {
      formRenderProps.onChange("cgst", {
        value: totalGSTamount / 2 || 0,
      });
      formRenderProps.onChange("sgst", {
        value: totalGSTamount / 2 || 0,
      });
    } else {
      formRenderProps.onChange("igst", {
        value: totalGSTamount || 0,
      });
    }
    formRenderProps.onChange("gst5", {
      value: totalGST5 || 0,
    });
    formRenderProps.onChange("gst12", {
      value: totalGST12 || 0,
    });
    formRenderProps.onChange("gst18", {
      value: totalGST18 || 0,
    });
    formRenderProps.onChange("gst28", {
      value: totalGST28 || 0,
    });
  }, [
    SalesChallanStateForGST,
    sales_challan_items?.map((item: any) => item?.gst_id).join("-"),
    sales_challan_items?.map((item: any) => item?.amount).join("-"),
  ]);

  useEffect(() => {
    if (sales_challan_items && sales_challan_items.length > 0) {
      sales_challan_items?.map((item: any, index: number) => {
        const quantity =
          item?.quantity?.toFixed(
            item?.unit_decimal ? item?.unit_decimal : 0
          ) || 0;
        const rate = item?.rate || 0;
        formRenderProps.onChange(`sales_challan_items.${index}.amount`, {
          value: +quantity * +rate,
        });
      });
    }
  }, [
    sales_challan_items?.map((item: any) => item?.quantity).join("-"),
    sales_challan_items?.map((item: any) => item?.rate).join("-"),
    sales_challan_items?.map((item: any) => item?.unit_decimal).join("-"),
  ]);

  useEffect(() => {
    if (sales_challan_items && sales_challan_items.length > 0) {
      sales_challan_items?.map((item: any, index: number) => {
        const unit_id = item?.unit_id;

        const unit = UnitList?.find((unit: any) => unit?.id === unit_id);
        if (unit) {
          formRenderProps.onChange(
            `sales_challan_items.${index}.unit_decimal`,
            {
              value: unit?.unit_decimal || 0,
            }
          );
        } else {
          formRenderProps.onChange(
            `sales_challan_items.${index}.unit_decimal`,
            {
              value: 0,
            }
          );
        }
      });
    }
  }, [sales_challan_items.map((item: any) => item?.unit_id).join("-")]);

  let totalamount = 0;
  let totalQty = 0;

  useEffect(() => {
    if (sales_challan_items && sales_challan_items.length > 0) {
      sales_challan_items?.map((item: any) => {
        totalQty += +item?.quantity || 0;
        totalamount += +item?.amount || 0;
      });
    }
    formRenderProps.onChange("totalQty", {
      value: totalQty,
    });
    formRenderProps.onChange("taxable_amount", {
      value: totalamount,
    });
  }, [
    sales_challan_items?.map((item: any) => item?.quantity).join("-"),
    sales_challan_items?.map((item: any) => item?.amount).join("-"),
  ]);

  // useEffect(() => {
  //   if (sales_challan_items && sales_challan_items.length > 0) {
  //     sales_challan_items?.map((item: any) => {
  //       totalamount += +item?.amount || 0;
  //     });
  //   }
  //   formRenderProps.onChange("total_amount", {
  //     value: totalamount,
  //   });
  // }, [sales_challan_items.map((item: any) => item?.amount).join("-")]);

  useEffect(() => {
    formRenderProps.onChange("sub_total", {
      value: +taxable_amount + +gst || 0,
    });
    formRenderProps.onChange("round_off", {
      value: Math.round(+sub_total) - +sub_total,
    });
    formRenderProps.onChange("grand_total", {
      value: +sub_total + +round_off || 0,
    });
  }, [taxable_amount, gst, sub_total, round_off]);

  return null;
};

const VendorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  // const location = useLocation();
  // const sales_challan_guid = location.state?.sales_challan_guid;
  const vendor_id = formRenderProps.valueGetter("vendor_id");
  // const isVendorRef = useRef(true);

  useEffect(() => {
    if (vendor_id) {
      const handleVendorChange = async () => {
        const payload = {
          id: vendor_id,
        };
        const response = await dispatch(getAccountByID(payload));
        if (response.meta.requestStatus === "fulfilled") {
          dispatch(setSalesChallanStateForGST(response.payload?.state_id));
          formRenderProps.onChange("state_id", {
            value: response.payload?.state_id,
          });
          formRenderProps.onChange("terms_day", {
            value: response.payload?.payment_due_days,
          });
        } else {
          formRenderProps.onChange("state_id", {
            value: null,
          });
          formRenderProps.onChange("terms_day", {
            value: null,
          });
        }
      };
      // if (!isVendorRef.current) {
      // handleVendorChange();
      // } else {
      // if (!sales_challan_guid) {
      handleVendorChange();
      // }
      // isVendorRef.current = false;
      // }
    } else {
      dispatch(setSalesChallanStateForGST(null));
      formRenderProps.onChange("state_id", {
        value: "",
      });
      formRenderProps.onChange("terms_day", {
        value: "",
      });
    }
  }, [vendor_id]);

  return null;
};

const DateChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const sales_challan_guid = location.state?.sales_challan_guid;
  const challan_date = formRenderProps.valueGetter("challan_date");
  const terms_day = formRenderProps.valueGetter("terms_day");
  // const due_date = formRenderProps.valueGetter("due_date");
  const isDateRef = useRef(true);

  useEffect(() => {
    const changeDueDate = () => {
      if (terms_day) {
        const dueDate = moment(challan_date, "YYYY-MM-DD").add(
          terms_day,
          "days"
        );

        formRenderProps.onChange("due_date", {
          value: moment(dueDate).toDate() || "",
        });
      }
      if (!terms_day) {
        formRenderProps.onChange("due_date", {
          value: "",
        });
      }
    };

    if (!isDateRef.current) {
      changeDueDate();
    } else {
      if (!sales_challan_guid) {
        changeDueDate();
      }
      isDateRef.current = false;
    }
  }, [challan_date, terms_day]);

  return null;
};

const ChallanNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const sales_challan_guid = location.state?.sales_challan_guid;
  const isChallanNoRef = useRef(false);

  // const voucher_type_id = formRenderProps.valueGetter("voucher_type_id");

  useEffect(() => {
    const fetchInvoiveNoDate = async () => {
      const response = await dispatch(generateChallanNoForSalesChallan());
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("challan_no_string", {
          value: response.payload?.challan_no_string || "",
        });
      }
    };
    if (!isChallanNoRef.current) {
      if (!sales_challan_guid) {
        fetchInvoiveNoDate();
      }
      isChallanNoRef.current = true;
    } else {
      isChallanNoRef.current = true;
    }
  }, []);

  return null;
};

const InnerItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const sales_challan_items = formRenderProps.valueGetter(
    "sales_challan_items"
  );
  const isFetchLotOption = React.useRef(true);
  const sales_challan_guid = location.state?.sales_challan_guid;

  const fetchGRNOptions = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id) {
        const response = await dispatch(
          getProductionLotNoByItemID(item.item_id)
        );
        const result =
          response.payload
            ?.filter((item: any) => item.item_id === item.item_id)
            .map((lotno: any) => ({
              value: `${lotno.lot_no}`,
              label: `${lotno.lot_no}`,
            })) || [];
        formRenderProps.onChange(`sales_challan_items.${index}.lotNoOptions`, {
          value: result,
        });
      } else {
        formRenderProps.onChange(`sales_challan_items.${index}.lotNoOptions`, {
          value: [],
        });
        formRenderProps.onChange(`sales_challan_items.${index}.lot_no`, {
          value: null,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  const fetchCurrentStock = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.lot_no) {
        const payload = {
          item_id: +item.item_id,
          lot_no: item.lot_no,
        };
        const response = await dispatch(
          getProductionLotNoCurrentStock(payload)
        );
        formRenderProps.onChange(`sales_challan_items.${index}.current_stock`, {
          value: response.payload?.actual_stock || 0,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  useEffect(() => {
    if (isFetchLotOption.current && sales_challan_guid) {
      sales_challan_items.forEach((item: any, index: number) => {
        fetchGRNOptions(index, item);
        fetchCurrentStock(index, item);
      });
      isFetchLotOption.current = false;
    }
  }, [sales_challan_guid]);

  return null;
};

const RouteChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const sales_challan_guid = location.state?.sales_challan_guid;
  const route_id = formRenderProps.valueGetter("route_id");

  useEffect(() => {
    if (route_id && !sales_challan_guid) {
      formRenderProps.onChange("sales_order_id", {
        value: "",
      });
      const payload = {
        dealer_name: "",
        route_id: route_id ? +route_id : null,
        financial_year: FINANCIAL_YEAR,
        limit: 50,
        skip: 0,
      };
      dispatch(getAllLimitedSalesOrders(payload));
    } else {
      formRenderProps.onChange("sales_order_id", {
        value: "",
      });
    }
  }, [route_id, sales_challan_guid]);

  return null;
};

const CreateSalesChallan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sales_challan_guid = location.state?.sales_challan_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.salesChallan.loading);
  const SalesChallanDetail = useAppSelector(
    (state) => state.salesChallan.SalesChallanDetail
  );
  const SalesChallanStateForGST = useAppSelector(
    (state) => state.salesChallan.SalesChallanStateForGST
  );
  const SalesOrderList = useAppSelector(
    (state) => state.salesOrder.SalesOrderList
  );
  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );
  const SchemeList = useAppSelector((state) => state.scheme.SchemeList);
  const RouteList = useAppSelector((state) => state.route.RouteList);
  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [SalesChallanDetail]);

  useEffect(() => {
    if (sales_challan_guid) {
      const payload = {
        sales_challan_guid: sales_challan_guid,
      };
      dispatch(getSalesChallanByID(payload));
    }
  }, [sales_challan_guid]);

  useEffect(() => {
    dispatch(findAllActiveUnit());
    dispatch(getAllSchemes());
    dispatch(getAllActiveGST());
    dispatch(getAllActiveRoute());
    dispatch(getAllVoucherTypes());

    return () => {
      dispatch(clearSalesOrderDetails());
      dispatch(clearSalesChallanDetails());
      dispatch(clearItemList());
    };
  }, []);

  const handleSOChange = async (
    sales_order_no: any,
    formRenderProps: FormRenderProps
  ) => {
    if (sales_order_no) {
      const payload = {
        sales_order_no: sales_order_no ? sales_order_no?.split("--")[0] : "",
        financial_year: FINANCIAL_YEAR,
      };
      const response = await dispatch(getSalesOrderByID(payload));
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("vendor_id", {
          value: response.payload?.dealer_id,
        });
        formRenderProps.onChange("free_scheme", {
          value: response.payload?.free_scheme,
        });
        formRenderProps.onChange("card_scheme", {
          value: response.payload?.card_scheme,
        });
        formRenderProps.onChange("vendor_options", {
          value: response.payload?.vendor_options,
        });
        const salesChallanItems =
          response.payload?.sales_order_items &&
          response.payload?.sales_order_items.length > 0 &&
          response.payload?.sales_order_items?.map((item: any) => {
            return {
              item_id: item?.item_id,
              item_options: item?.item_options,
              mrp: item?.mrp,
              gst_id: item?.gst_id,
              quantity: item?.quantity,
              rate: item?.rate,
              unit_id: item?.unit_id,
              unit_decimal: item?.unit_decimal,
              amount: item?.quantity * item?.rate,
              lotNoOptions:
                item?.lot_no_list?.map((lotno: any) => ({
                  label: lotno?.lot_no,
                  value: lotno?.lot_no,
                })) || [],
            };
          });
        formRenderProps.onChange("sales_challan_items", {
          value: salesChallanItems || [],
        });
      } else {
        formRenderProps.onChange("vendor_id", {
          value: null,
        });
        formRenderProps.onChange("free_scheme", {
          value: null,
        });
        formRenderProps.onChange("card_scheme", {
          value: null,
        });
        formRenderProps.onChange("vendor_options", {
          value: [],
        });
        formRenderProps.onChange("po_date", {
          value: "",
        });
        formRenderProps.onChange("sales_challan_items", {
          value: [],
        });
      }
    }
  };

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (sales_challan_guid) {
      try {
        const updatePayload: any = {
          id: values?.id ? +values?.id : null,
          sales_challan_guid: sales_challan_guid,
          sales_order_no: SalesChallanDetail?.sales_order_no
            ? SalesChallanDetail?.sales_order_no
            : "",
          sales_order_id: SalesChallanDetail?.sales_order_id
            ? +SalesChallanDetail?.sales_order_id
            : null,
          financial_year: FINANCIAL_YEAR,
          // challan_no_string: values?.challan_no_string
          //   ? values?.challan_no_string
          //   : "",
          cartoon: values?.cartoon ? +values?.cartoon : null,
          scheme: values?.scheme ? values?.scheme?.join(", ") : "",
          remarks: values?.remarks ? values?.remarks : "",
          route_id: values?.route_id ? +values?.route_id : null,
          bag: values?.bag ? +values?.bag : null,
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          transport_name: values?.transport_name ? values?.transport_name : "",
          // delivery_slip_no: values?.delivery_slip_no
          //   ? values?.delivery_slip_no
          //   : "",
          challan_date: values?.challan_date
            ? moment(values?.challan_date).format("YYYY-MM-DD")
            : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          is_delivered: values?.is_delivered ? values?.is_delivered : false,
          terms_day: values?.terms_day ? +values?.terms_day : null,
          due_date: values?.due_date
            ? moment(values?.due_date).format("YYYY-MM-DD")
            : null,
          // sales_ledger_id: values?.sales_ledger_id
          //   ? +values?.sales_ledger_id
          //   : null,
          total_amount: values?.grand_total ? +values?.grand_total : null,
          gst: values?.gst ? +values?.gst?.toFixed(2) : null,
          sales_challan_items: values?.sales_challan_items
            ? values?.sales_challan_items?.map((salesChallanitem: any) => {
                return {
                  id: salesChallanitem?.id ? +salesChallanitem?.id : 0,
                  sales_challan_id: values?.id ? +values?.id : null,
                  item_id: salesChallanitem?.item_id
                    ? +salesChallanitem?.item_id
                    : null,
                  lot_no: salesChallanitem?.lot_no
                    ? salesChallanitem?.lot_no
                    : "",
                  mrp: salesChallanitem?.mrp ? +salesChallanitem?.mrp : null,
                  quantity: salesChallanitem?.quantity
                    ? +salesChallanitem?.quantity?.toFixed(
                        salesChallanitem.unit_decimal
                          ? salesChallanitem.unit_decimal
                          : 0
                      )
                    : 0,
                  free_quantity: salesChallanitem?.free_quantity
                    ? +salesChallanitem?.free_quantity?.toFixed(
                        salesChallanitem.unit_decimal
                          ? salesChallanitem.unit_decimal
                          : 0
                      )
                    : 0,
                  unit_id: salesChallanitem?.unit_id
                    ? +salesChallanitem?.unit_id
                    : null,
                  rate: salesChallanitem?.rate ? +salesChallanitem?.rate : null,
                  gst_id: salesChallanitem?.gst_id
                    ? parseInt(salesChallanitem?.gst_id.split("--")[0])
                    : null,
                  amount: salesChallanitem?.amount
                    ? +salesChallanitem?.amount
                    : null,
                };
              })
            : [],
        };
        if (SalesChallanStateForGST === GUJARATIDFORGST) {
          updatePayload.cgst = values?.cgst ? +values?.cgst?.toFixed(2) : null;
          updatePayload.sgst = values?.sgst ? +values?.sgst?.toFixed(2) : null;
          updatePayload.igst = null;
        } else {
          updatePayload.igst = values?.igst ? +values?.igst?.toFixed(2) : null;
          updatePayload.cgst = null;
          updatePayload.sgst = null;
        }

        const response = await dispatch(updateSalesChallan(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/saleschallan");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload: any = {
          sales_order_no: values?.sales_order_id
            ? values?.sales_order_id?.split("--")[0]
            : "",
          sales_order_id: values?.sales_order_id
            ? +values?.sales_order_id?.split("--")[1]
            : null,
          financial_year: FINANCIAL_YEAR,
          // challan_no_string: values?.challan_no_string
          //   ? values?.challan_no_string
          //   : "",
          challan_date: values?.challan_date
            ? moment(values?.challan_date).format("YYYY-MM-DD")
            : "",
          scheme: values?.scheme ? values?.scheme?.join(", ") : "",
          remarks: values?.remarks ? values?.remarks : "",
          route_id: values?.route_id ? +values?.route_id : null,
          cartoon: values?.cartoon ? +values?.cartoon : null,
          bag: values?.bag ? +values?.bag : null,
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          transport_name: values?.transport_name ? values?.transport_name : "",
          // delivery_slip_no: values?.delivery_slip_no
          //   ? values?.delivery_slip_no
          //   : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          is_delivered: values?.is_delivered ? values?.is_delivered : false,
          terms_day: values?.terms_day ? +values?.terms_day : null,
          due_date: values?.due_date
            ? moment(values?.due_date).format("YYYY-MM-DD")
            : null,
          // sales_ledger_id: values?.sales_ledger_id
          //   ? +values?.sales_ledger_id
          //   : null,
          total_amount: values?.grand_total ? +values?.grand_total : null,
          gst: values?.gst ? +values?.gst?.toFixed(2) : null,
          sales_challan_items: values?.sales_challan_items
            ? values?.sales_challan_items?.map((salesChallanitem: any) => {
                return {
                  id: salesChallanitem?.id ? +salesChallanitem?.id : 0,
                  sales_challan_id: values?.id ? +values?.id : null,
                  item_id: salesChallanitem?.item_id
                    ? +salesChallanitem?.item_id
                    : null,
                  lot_no: salesChallanitem?.lot_no
                    ? salesChallanitem?.lot_no
                    : "",
                  mrp: salesChallanitem?.mrp ? +salesChallanitem?.mrp : null,
                  quantity: salesChallanitem?.quantity
                    ? +salesChallanitem?.quantity?.toFixed(
                        salesChallanitem.unit_decimal
                          ? salesChallanitem.unit_decimal
                          : 0
                      )
                    : 0,
                  free_quantity: salesChallanitem?.free_quantity
                    ? +salesChallanitem?.free_quantity?.toFixed(
                        salesChallanitem.unit_decimal
                          ? salesChallanitem.unit_decimal
                          : 0
                      )
                    : 0,
                  unit_id: salesChallanitem?.unit_id
                    ? +salesChallanitem?.unit_id
                    : null,
                  rate: salesChallanitem?.rate ? +salesChallanitem?.rate : null,
                  gst_id: salesChallanitem?.gst_id
                    ? parseInt(salesChallanitem?.gst_id.split("--")[0])
                    : null,
                  amount: salesChallanitem?.amount
                    ? +salesChallanitem?.amount
                    : null,
                };
              })
            : [],
        };
        if (SalesChallanStateForGST === GUJARATIDFORGST) {
          insertPayload.cgst = values?.cgst ? +values?.cgst?.toFixed(2) : null;
          insertPayload.sgst = values?.sgst ? +values?.sgst?.toFixed(2) : null;
          insertPayload.igst = null;
        } else {
          insertPayload.igst = values?.igst ? +values?.igst?.toFixed(2) : null;
          insertPayload.cgst = null;
          insertPayload.sgst = null;
        }
        const response = await dispatch(createSalesChallan(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/saleschallan");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={SalesChallanDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {sales_challan_guid
                      ? "Update Sales Challan"
                      : "Add Sales Challan"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="voucher_type_id"
                    label="Voucher Type"
                    placeholder="Voucher Type"
                    astrike={true}
                    disabled={sales_challan_guid && true}
                    validator={requiredValidator}
                    component={FormSelectionField}
                    options={VoucherTypeList?.map((scheme: IVoucherType) => {
                      return {
                        value: scheme?.id,
                        label: scheme?.name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="challan_no_string"
                    label="Challan No"
                    disabled={true}
                    placeholder="Challan No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="route_id"
                    label="Route"
                    placeholder="Route"
                    component={FormSelectionField}
                    astrike={true}
                    validator={
                      !sales_challan_guid ? requiredValidator : skipValidator
                    }
                    disabled={sales_challan_guid ? true : false}
                    options={RouteList?.map((route: IRoute) => {
                      return {
                        value: route?.id,
                        label: route?.route_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  {(formRenderProps.valueGetter("free_scheme") ||
                    formRenderProps.valueGetter("card_scheme")) && (
                    <div
                      style={{
                        marginTop: 30,
                        background: "whitesmoke",
                        padding: "7px 10px",
                        borderRadius: 5,
                        display: "flex",
                        gap: 10,
                      }}
                    >
                      {formRenderProps.valueGetter("free_scheme") && (
                        <Field
                          name="free_scheme"
                          wrapperStyle={{ marginTop: 0 }}
                          label="Free Scheme"
                          disabled={true}
                          component={FormCheckbox}
                          labelClassName={"fw-bold ms-2"}
                        />
                      )}
                      {formRenderProps.valueGetter("card_scheme") && (
                        <Field
                          name="card_scheme"
                          wrapperStyle={{ marginTop: 0 }}
                          label="Card Scheme"
                          disabled={true}
                          component={FormCheckbox}
                          labelClassName={"fw-bold ms-2"}
                        />
                      )}
                    </div>
                  )}
                </GridLayoutItem>
                <GridLayoutItem>
                  <div
                    style={{
                      marginTop: 30,
                      background: "whitesmoke",
                      padding: "7px 10px",
                      borderRadius: 5,
                    }}
                  >
                    <Field
                      name="is_delivered"
                      wrapperStyle={{ marginTop: 0 }}
                      label="Delivered"
                      placeholder="Delivered"
                      component={FormCheckbox}
                      labelClassName={"fw-bold ms-2"}
                    />
                  </div>
                </GridLayoutItem>
                {sales_challan_guid ? (
                  <GridLayoutItem colSpan={2}>
                    <Field
                      wrapperClassName="w-100"
                      name="sales_order_id_label"
                      label="Sales Order No"
                      placeholder="Sales Order No"
                      disabled={true}
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                ) : (
                  <GridLayoutItem
                    colSpan={2}
                    style={{ display: "flex", gap: 10 }}
                  >
                    <Field
                      wrapperClassName="w-100"
                      name="sales_order_id"
                      label="Sales Order No"
                      placeholder="Sales Order No"
                      disabled={
                        sales_challan_guid ||
                        (!sales_challan_guid &&
                          !formRenderProps.valueGetter("route_id"))
                          ? true
                          : false
                      }
                      component={FormSelectionField}
                      options={SalesOrderList?.map((so: any) => {
                        return {
                          value: `${so?.sales_order_no}--${so?.id}`,
                          label: `${so?.sales_order_no} - ${so?.sales_date} - ${so?.account_name}`,
                        };
                      })}
                    />
                    <ButtonForAll
                      label={"View"}
                      disabled={
                        sales_challan_guid ||
                        !formRenderProps.valueGetter("sales_order_id")
                          ? true
                          : false
                      }
                      style={{ marginTop: 35 }}
                      onClick={() =>
                        handleSOChange(
                          formRenderProps.valueGetter("sales_order_id"),
                          formRenderProps
                        )
                      }
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="challan_date"
                    label="Challan Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="sales_ledger_id"
                    label="Sales Ledger"
                    placeholder="Sales Ledger"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={
                      []
                      //     CONDITION?.map((condition: any) => {
                      //   return {
                      //     value: condition?.value,
                      //     label: condition?.label,
                      //   };
                      // })
                    }
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="vendor_id"
                    label="Customer Name"
                    placeholder="Customer Name"
                    isAddNew={true}
                    addNewLink="account/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleVendorSearchChange(
                        search,
                        `vendor_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("vendor_options")
                        ?.map((item: any) => {
                          return {
                            value: item?.id,
                            label: item?.account_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="scheme"
                    label="Scheme"
                    placeholder="Scheme"
                    component={FormMultiSelectionFiled}
                    options={SchemeList?.map((scheme: IScheme) => {
                      return {
                        value: scheme?.id,
                        label: scheme?.scheme_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="terms_day"
                    label="Terms Day"
                    placeholder="0"
                    disabled={true}
                    maxNumber={999}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="due_date"
                    label="Due Date"
                    format="dd/MM/yyyy"
                    disabled={true}
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="delivery_slip_no"
                    label="Delivery Slip No"
                    placeholder="0"
                    component={FormTextField}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="transport_name"
                    label="Transport Name"
                    placeholder="i.e. John Smith"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_no"
                    label="Vehicle No"
                    placeholder="i.e. GJ01AA1111"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="cartoon"
                    label="Cartoon"
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="bag"
                    label="Bag"
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormTextArea}
                    rows={2}
                  />
                </GridLayoutItem>
                <QtyChangeWatcher formRenderProps={formRenderProps} />
                <VendorChangeWatcher formRenderProps={formRenderProps} />
                <DateChangeWatcher formRenderProps={formRenderProps} />
                <ChallanNoChangeWatcher formRenderProps={formRenderProps} />
                <InnerItemChangeWatcher formRenderProps={formRenderProps} />
                <RouteChangeWatcher formRenderProps={formRenderProps} />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  // style={{ borderBottom: "1px solid lightgray" }}
                >
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleItemSearchChange={handleItemSearchChange}
                    component={SCItemDetailsArray}
                    name="sales_challan_items"
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <SalesChallanTaxSummary formRenderProps={formRenderProps} />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td
                          style={{
                            width: "60%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Total
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "30%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="taxable_amount"
                            format="n2"
                            component={FormNumericTextField}
                            disabled="true"
                          />
                        </td>
                      </tr>
                      {SalesChallanStateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "60%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            CGST
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "30%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="cgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {SalesChallanStateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "60%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            SGST
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "30%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="sgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {SalesChallanStateForGST !== GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "60%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            IGST
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "30%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="igst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td
                          style={{
                            width: "60%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Sub Total
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "30%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="sub_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "60%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Round Off
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "30%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="round_off"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "0%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Grand Total
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "30%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="grand_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={sales_challan_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/saleschallan")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

const SalesChallanTaxSummary: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const SalesChallanStateForGST = useAppSelector(
    (state) => state.salesChallan.SalesChallanStateForGST
  );
  const sales_challan_guid = location.state?.sales_challan_guid;
  const SalesChallanDetail = useAppSelector(
    (state) => state.salesChallan.SalesChallanDetail
  );
  const isVendorRef = useRef(true);
  const [isIGST, setIsIgst] = React.useState<boolean>(false);
  const totalGST = formRenderProps.valueGetter("gst");
  const totalGST5 = formRenderProps.valueGetter("gst5");
  const totalGST12 = formRenderProps.valueGetter("gst12");
  const totalGST18 = formRenderProps.valueGetter("gst18");
  const totalGST28 = formRenderProps.valueGetter("gst28");

  useEffect(() => {
    if (sales_challan_guid) {
      if (!isVendorRef.current) {
        setIsIgst(SalesChallanStateForGST !== GUJARATIDFORGST);
      } else {
        if (SalesChallanDetail?.igst && SalesChallanDetail?.igst !== null) {
          setIsIgst(true);
        } else {
          setIsIgst(false);
        }
        isVendorRef.current = false;
      }
    } else {
      setIsIgst(SalesChallanStateForGST !== GUJARATIDFORGST);
    }
  }, [SalesChallanStateForGST]);

  return (
    <>
      <div>
        <table style={{ width: "100%" }} className="sctaxsummary-table">
          {(totalGST5 > 0 ||
            totalGST12 > 0 ||
            totalGST18 > 0 ||
            totalGST28 > 0) && (
            <tr style={{ background: "lightgray" }}>
              <td colSpan={4}>
                <h5 className="m-0">Tax Summery</h5>
              </td>
            </tr>
          )}
          {isIGST && totalGST5 > 0 && (
            <tr>
              <td
                style={{
                  width: "30%",
                  fontWeight: 600,
                }}
              >
                IGST 5%
              </td>
              <td style={{ width: "10%", textAlign: "center" }}>:</td>
              <td style={{ width: "10%", textAlign: "center" }}>5 %</td>
              <td
                style={{
                  width: "50%",
                  textAlign: "right",
                  paddingRight: 45,
                }}
              >
                {formatIndianNumber(totalGST5)}
              </td>
            </tr>
          )}
          {isIGST && totalGST12 > 0 && (
            <tr>
              <td
                style={{
                  width: "30%",
                  fontWeight: 600,
                }}
              >
                IGST 12%
              </td>
              <td style={{ width: "10%", textAlign: "center" }}>:</td>
              <td style={{ width: "10%", textAlign: "center" }}>12 %</td>
              <td
                style={{
                  width: "50%",
                  textAlign: "right",
                  paddingRight: 45,
                }}
              >
                {formatIndianNumber(totalGST12)}
              </td>
            </tr>
          )}
          {isIGST && totalGST18 > 0 && (
            <tr>
              <td
                style={{
                  width: "30%",
                  fontWeight: 600,
                }}
              >
                IGST 18%
              </td>
              <td style={{ width: "10%", textAlign: "center" }}>:</td>
              <td style={{ width: "10%", textAlign: "center" }}>18 %</td>
              <td
                style={{
                  width: "50%",
                  textAlign: "right",
                  paddingRight: 45,
                }}
              >
                {formatIndianNumber(totalGST18)}
              </td>
            </tr>
          )}
          {isIGST && totalGST28 > 0 && (
            <tr>
              <td
                style={{
                  width: "30%",
                  fontWeight: 600,
                }}
              >
                IGST 28%
              </td>
              <td style={{ width: "10%", textAlign: "center" }}>:</td>
              <td style={{ width: "10%", textAlign: "center" }}>28 %</td>
              <td
                style={{
                  width: "50%",
                  textAlign: "right",
                  paddingRight: 45,
                }}
              >
                {formatIndianNumber(totalGST28)}
              </td>
            </tr>
          )}
          {!isIGST && totalGST5 > 0 && (
            <>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  CGST 2.5%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>2.5 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST5 / 2)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  SGST 2.5%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>2.5 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST5 / 2)}
                </td>
              </tr>
            </>
          )}
          {!isIGST && totalGST12 > 0 && (
            <>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  CGST 6%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>6 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST12 / 2)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  SGST 6%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>6 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST12 / 2)}
                </td>
              </tr>
            </>
          )}
          {!isIGST && totalGST18 > 0 && (
            <>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  CGST 9%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>9 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST18 / 2)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  SGST 9%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>9 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST18 / 2)}
                </td>
              </tr>
            </>
          )}
          {!isIGST && totalGST28 > 0 && (
            <>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  CGST 14%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>14 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST28 / 2)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  SGST 14%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>14 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST28 / 2)}
                </td>
              </tr>
            </>
          )}

          {!isIGST && totalGST > 0 && (
            <>
              <tr style={{ background: "whitesmoke" }}>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  Total CGST
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}></td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST / 2)}
                </td>
              </tr>
              <tr style={{ background: "whitesmoke" }}>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  Total SGST
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}></td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST / 2)}
                </td>
              </tr>
            </>
          )}
          {isIGST && totalGST > 0 && (
            <tr style={{ background: "whitesmoke" }}>
              <td
                style={{
                  width: "30%",
                  fontWeight: 600,
                }}
              >
                Total IGST
              </td>
              <td style={{ width: "10%", textAlign: "center" }}>:</td>
              <td style={{ width: "10%", textAlign: "center" }}></td>
              <td
                style={{
                  width: "50%",
                  textAlign: "right",
                  paddingRight: 45,
                }}
              >
                {formatIndianNumber(totalGST)}
              </td>
            </tr>
          )}
        </table>
      </div>
    </>
  );
};

export default CreateSalesChallan;
