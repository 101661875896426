import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormDatePicker from "../../components/formFields/FormDateField";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import moment from "moment";

import PaymentEntryDetailsArray from "./PaymentEntryDetailsArray";
import {
  createPayment,
  generatePaymentNoForPayment,
  getPaymentByID,
  updatePayment,
} from "./services/payment.services";
import {
  // clearOutstandingPaymentList,
  clearPaymentDetails,
  setPaymentBillDialogOpenIndex,
} from "./paymentSlice";
import PaymentBillDetailsArray from "./PaymentBillDetailsArray";
import {
  getAllAccountIncremental,
  getAllPaymentAndReceipt,
} from "../account/services/account.services";
import FormTextArea from "../../components/formFields/FormTextArea";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BiInfoCircle } from "react-icons/bi";
import FormSelectionField from "../../components/formFields/FormSelectionField";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const BillPaymentEntryChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const payment_bill_entry =
    formRenderProps.valueGetter("payment_bill_entry") || [];
  const payment_entry = formRenderProps.valueGetter("payment_entry");
  const PaymentBillDialogOpenIndex = useAppSelector(
    (state) => state.payment.PaymentBillDialogOpenIndex
  );

  const OutstandingPaymentList = useAppSelector(
    (state) => state.payment.OutstandingPaymentList
  );
  const location = useLocation();
  const payment_guid = location.state?.payment_guid;

  // const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      PaymentBillDialogOpenIndex >= 0 &&
      payment_entry?.[PaymentBillDialogOpenIndex]?.payment_entry_referance
        ?.length > 0
    ) {
      const IsPaymentRefID =
        payment_entry?.[PaymentBillDialogOpenIndex]
          ?.payment_entry_referance?.[0]?.payment_ref_id;

      if (payment_guid && IsPaymentRefID) {
        const OldBillEntry =
          payment_entry?.[PaymentBillDialogOpenIndex]?.payment_entry_referance;

        const referenceMap = new Map<
          number,
          { refernace_name: string; referance_amount: number }
        >(
          OldBillEntry.map((entry: any) => [
            entry.refernace_bill_id,
            {
              refernace_name: entry.refernace_name,
              referance_amount: entry.referance_amount,
            },
          ])
        );
        const mergedArray = OutstandingPaymentList.map((bill: any) => {
          const match = referenceMap.get(bill.bill_no);
          const pendingAmountFullPaid = bill.final_pending_amount;

          if (match && match?.refernace_name === bill.bill_no_string) {
            if (!pendingAmountFullPaid) {
              return {
                ...bill,
                referance_amount: match.referance_amount,
                isfullpayment: true,
              };
            } else {
              return {
                ...bill,
                referance_amount: match.referance_amount,
                isfullpayment: false,
              };
            }
          }

          return { ...bill }; // Return the bill unchanged if no match
        });

        formRenderProps.onChange("payment_bill_entry", {
          value: mergedArray || [],
        });
      } else {
        formRenderProps.onChange("payment_bill_entry", {
          value:
            payment_entry?.[PaymentBillDialogOpenIndex]
              ?.payment_entry_referance || [],
        });
      }
    } else {
      formRenderProps.onChange("payment_bill_entry", {
        value: JSON.parse(JSON.stringify(OutstandingPaymentList)) || [],
      });
    }
  }, [PaymentBillDialogOpenIndex, OutstandingPaymentList?.[0]?.bill_no]);

  useEffect(() => {
    const calculateTotals = (entries: typeof payment_entry) => {
      const totals: Record<string, number> = {};
      entries.forEach((entry: any) => {
        const key = `payment_total_amount`;
        totals[key] = (totals[key] || 0) + entry.amount;
      });
      return totals;
    };
    const result = calculateTotals(payment_entry);
    formRenderProps.onChange(`payment_total_amount`, {
      value: result.payment_total_amount || 0,
    });
    if (payment_entry && payment_entry?.length > 0) {
      formRenderProps.onChange(`payment_entry.${0}.db_total`, {
        value: result || {},
      });
    }
  }, [
    payment_entry?.map((item: any) => item?.amount).join("-"),
    payment_entry?.map((item: any) => item?.debit_credit).join("-"),
    payment_entry?.map((item: any) => item?.ledger_id).join("-"),
  ]);

  useEffect(() => {
    let TotalAmount = 0;
    const PaymentEntryOpenIndex = payment_entry[PaymentBillDialogOpenIndex];
    if (PaymentEntryOpenIndex) {
      payment_entry[PaymentBillDialogOpenIndex] = {
        ...PaymentEntryOpenIndex,
        payment_entry_referance: [...payment_bill_entry],
      };
    }
    if (
      payment_entry[PaymentBillDialogOpenIndex]?.payment_entry_referance
        ?.length > 0
    ) {
      TotalAmount = payment_entry[
        PaymentBillDialogOpenIndex
      ]?.payment_entry_referance?.reduce(
        (total: number, item: any) => total + (item?.referance_amount || 0),
        0
      );
    } else {
      TotalAmount = 0;
    }
    const RemaingAmount =
      (PaymentEntryOpenIndex?.amount ?? 0) - (TotalAmount ?? 0);
    const IndexDetailsObj = {
      remainingAmount: RemaingAmount,

      ledger_name: PaymentEntryOpenIndex?.ledger_options?.find(
        (account: any) => account?.id === PaymentEntryOpenIndex?.ledger_id
      )?.account_name,
      ...PaymentEntryOpenIndex,
    };
    formRenderProps.onChange(`payment_bill_entry.${0}.IndexDetails`, {
      value: IndexDetailsObj || 0,
    });
  }, [
    PaymentBillDialogOpenIndex,
    OutstandingPaymentList?.[0]?.bill_no,
    payment_entry?.map((item: any) => item?.amount).join("-"),
    payment_entry?.map((item: any) => item?.debit_credit).join("-"),
    payment_entry?.map((item: any) => item?.ledger_id).join("-"),
    payment_bill_entry?.map((item: any) => item?.referance_amount).join("-"),
  ]);

  return null;
};

const PaymentNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const payment_guid = location.state?.payment_guid;
  const isPaymentNoRef = useRef(false);

  useEffect(() => {
    const fetchPaymentNo = async () => {
      const response = await dispatch(generatePaymentNoForPayment());
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("payment_no_string", {
          value: response.payload?.payment_no_string || "",
        });
        formRenderProps.onChange("payment_no", {
          value: response.payload?.payment_no_string || "",
        });
      }
    };
    if (!isPaymentNoRef.current) {
      if (!payment_guid) {
        fetchPaymentNo();
      }
      isPaymentNoRef.current = true;
    } else {
      isPaymentNoRef.current = true;
    }
  }, []);

  return null;
};

const CreatePayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const payment_guid = location.state?.payment_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.payment.loading);
  const PaymentDetail = useAppSelector((state) => state.payment.PaymentDetail);
  const AccountPaymentAndReceiptList = useAppSelector(
    (state) => state.account.AccountPaymentAndReceiptList
  );
  // const PaymentStateForGST = useAppSelector(
  //   (state) => state.payment.PaymentStateForGST
  // );

  const [formKey, setFormKey] = React.useState(1);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [PaymentDetail]);

  useEffect(() => {
    if (payment_guid) {
      const payload = {
        payment_guid: payment_guid,
      };
      dispatch(getPaymentByID(payload));
    }
  }, [payment_guid]);

  useEffect(() => {
    const payload = {
      account_name: "",
      under_group_id: "16,17,18",
    };
    dispatch(getAllPaymentAndReceipt(payload));

    return () => {
      dispatch(setPaymentBillDialogOpenIndex(-1));
      dispatch(clearPaymentDetails());
    };
  }, []);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    // if (values?.DebitTotalAmount !== values?.CreditTotalAmount) {
    //   if (values?.DebitTotalAmount > values?.CreditTotalAmount) {
    //     ErrorToast("Debit Amount is Greater than Credit Amount");
    //   } else {
    //     ErrorToast("Credit Amount is Greater than Debit Amount");
    //   }
    //   return;
    // }
    const formdata: any = {
      financial_year: values?.financial_year
        ? +values?.financial_year
        : FINANCIAL_YEAR,
      // payment_no_string: values?.payment_no_string,
      // payment_no: values?.payment_no,
      payment_date: values?.payment_date
        ? moment(values?.payment_date).format("YYYY-MM-DD")
        : "",
      payment_total_amount: values?.payment_total_amount,
      remarks: values?.remarks,
      ledger_id: values?.ledger_id,
      payment_entry: values?.payment_entry
        ?.filter((entry: any) => entry?.ledger_id)
        ?.map((entry: any) => ({
          debit_credit: entry?.debit_credit,
          ledger_id: entry?.ledger_id,
          amount: entry?.amount,
          payment_entry_referance: entry?.payment_entry_referance
            ? entry?.payment_entry_referance
                ?.filter((pay_ref: any) => pay_ref?.referance_amount)
                ?.map((pay_ref: any) => {
                  if (pay_ref?.bill_no) {
                    return {
                      referance_type: entry?.debit_credit,
                      refernace_name: pay_ref?.bill_no_string,
                      refernace_bill_id: pay_ref?.bill_no,
                      referance_amount: pay_ref?.referance_amount,
                    };
                  }
                  return pay_ref;
                })
            : [],
        })),
    };
    if (payment_guid) {
      try {
        const updatePayload: any = {
          payment_guid: payment_guid,
          id: values?.id ? +values?.id : null,
          ...formdata,
        };
        const response = await dispatch(updatePayment(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/payment");
          dispatch(setPaymentBillDialogOpenIndex(-1));
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const response = await dispatch(createPayment(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/payment");
          dispatch(setPaymentBillDialogOpenIndex(-1));
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={PaymentDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {payment_guid ? "Update Payment" : "Add Payment"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="payment_no_string"
                    label="Payment No"
                    disabled={true}
                    placeholder="Payment No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="payment_date"
                    label="Payment Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    label="Ledger Name"
                    name="ledger_id"
                    component={FormSelectionField}
                    options={AccountPaymentAndReceiptList.map((ledger: any) => {
                      return {
                        value: ledger?.id,
                        label: ledger?.account_name,
                      };
                    })}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <PaymentNoChangeWatcher formRenderProps={formRenderProps} />
                <BillPaymentEntryChangeWatcher
                  formRenderProps={formRenderProps}
                />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography.h4>Particulars</Typography.h4>
                  <Tooltip
                    anchorElement="target"
                    position="left"
                    parentTitle={true}
                  >
                    <BiInfoCircle
                      title="Escape to Delete Entry."
                      style={{ color: "red", cursor: "pointer" }}
                    />
                  </Tooltip>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleVendorSearchChange={handleVendorSearchChange}
                    component={PaymentEntryDetailsArray}
                    name="payment_entry"
                  />
                  {dialogName === "BillItemDeleteDialog" && (
                    <FieldArray
                      formRenderProps={formRenderProps}
                      component={PaymentBillDetailsArray}
                      name="payment_bill_entry"
                    />
                  )}
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    rows={2}
                    id="remarks_for_focus"
                    component={FormTextArea}
                    // validator={requiredValidator}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={payment_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => {
                        navigate("/payment");
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreatePayment;
