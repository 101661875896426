import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { formatIndianNumber } from "../../_helper/helper";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getRetailerSalesOrderByID } from "../retailersalesorder/services/retailerSalesOrder.services";
import { clearRetailerSalesOrderDetails } from "../retailersalesorder/retailerSalesOrderSlice";
import { LoadingPanel } from "../../components/layout/Loading";

const SRdistributorOrderView: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.state?.id;
  const distributor_id = location.state?.distributor_id;
  const date = location.state?.date;

  const loading = useAppSelector((state) => state.retailerSalesOrder.loading);
  const RetailerSalesOrderDetail = useAppSelector(
    (state) => state.retailerSalesOrder.RetailerSalesOrderDetail
  );
  console.log("id", id, distributor_id, date);
  console.log("RetailerSalesOrderDetail", RetailerSalesOrderDetail);

  useEffect(() => {
    return () => {
      dispatch(clearRetailerSalesOrderDetails());
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getRetailerSalesOrderByID(+id));
    }
  }, [id]);

  let totalAmount = 0;

  RetailerSalesOrderDetail &&
    RetailerSalesOrderDetail?.retailer_sales &&
    RetailerSalesOrderDetail?.retailer_sales?.length > 0 &&
    RetailerSalesOrderDetail?.retailer_sales?.map(
      (salesorderitem: any) =>
        salesorderitem?.retailer_sales_order_list &&
        salesorderitem?.retailer_sales_order_list.length > 0 &&
        salesorderitem?.retailer_sales_order_list?.map((item: any) => {
          totalAmount += item?.amount;
        })
    );

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <div className="order-container" style={{ padding: 12, minWidth: 800 }}>
        <div className="row">
          <div className="col-12">
            <Typography.h4 className="text-center">
              Retailer Sales Order
            </Typography.h4>
          </div>
          <div className="col-12 order-print-page">
            <p className="fw-600">Retailer Name : {RetailerSalesOrderDetail?.retailer_name}</p>
          </div>
          <div className="col-12 order-print-page">
            <table
              cellSpacing={1}
              cellPadding={2}
              className="table table-bordered w-100  order-print-table mb-0"
              style={{ marginTop: 1 }}
            >
              <tr className="text-center fw-600" style={{ fontSize: 12 }}>
                <th className="order-print-th" style={{ width: "10%" }}>
                  Sr No.
                </th>
                <th className="order-print-th" style={{ width: "40%" }}>
                  Description Of Goods
                </th>
                <th className="order-print-th" style={{ width: "10%" }}>
                  MRP
                </th>
                <th className="order-print-th" style={{ width: "10%" }}>
                  Quantity
                </th>
                <th className="order-print-th" style={{ width: "10%" }}>
                  Per
                </th>
                <th className="order-print-th" style={{ width: "10%" }}>
                  Rate
                </th>
                <th className="order-print-th" style={{ width: "10%" }}>
                  Amount
                </th>
              </tr>
              {RetailerSalesOrderDetail &&
                RetailerSalesOrderDetail?.retailer_sales &&
                RetailerSalesOrderDetail?.retailer_sales?.length > 0 &&
                RetailerSalesOrderDetail?.retailer_sales?.map((item: any) => (
                  <>
                    <tr>
                      <td
                        className="fw-600"
                        colSpan={7}
                        style={{ fontSize: 12 }}
                      >
                        {item?.item_group}
                      </td>
                    </tr>
                    {item?.retailer_sales_order_list &&
                      item?.retailer_sales_order_list?.length > 0 &&
                      item?.retailer_sales_order_list?.map(
                        (subitem: any, index: number) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{subitem?.product_name}</td>
                            <td>Rs. {subitem?.mrp || 0}</td>
                            <td className="text-end">{subitem?.quantity}</td>
                            <td>{subitem?.unit_name}</td>
                            <td>
                              Rs. {formatIndianNumber(subitem?.rate) || 0}
                            </td>
                            <td className=" text-end fw-500">
                              {formatIndianNumber(subitem?.amount)}
                            </td>
                          </tr>
                        )
                      )}
                  </>
                ))}
              <tr style={{ background: "whitesmoke" }}>
                <td colSpan={6} className="text-end fw-600">
                  Grand Total
                </td>
                <td className="text-end fw-600" style={{ fontSize: 14 }}>
                  {formatIndianNumber(totalAmount)}
                </td>
              </tr>
            </table>
          </div>
          <div
            className="col-12"
            style={{
              // textAlign: "end",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              width: "100%",
            }}
          >
            <Button
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              style={{ marginRight: 4 }}
              onClick={() =>
                navigate("/distributororder", {
                  state: {
                    distributor_id: distributor_id ? +distributor_id : null,
                    date: date ? date : "",
                  },
                })
              }
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SRdistributorOrderView;
