import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast } from "../../../components/toast/Toasts";

export const getAllCurrentDateWiseDistributor = createAsyncThunk(
  "SalesRepresentative/FindAllCurrentDateWiseDistributor",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesRepresentative/FindAllCurrentDateWiseDistributor`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Distributors", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllCurrentDateAndDistributorWiseRetailer = createAsyncThunk(
  "SalesRepresentative/FindAllCurrentDateANDDistributorWiseRetailer",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesRepresentative/FindAllCurrentDateANDDistributorWiseRetailer`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Retailers", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const insertStartDistributorVisit = createAsyncThunk(
  "SalesRepresentative/InsertStartDistributorVisit",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesRepresentative/InsertStartDistributorVisit`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting start time", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const insertEndDistributorVisit = createAsyncThunk(
  "SalesRepresentative/InsertEndDistributorVisit",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesRepresentative/InsertEndDistributorVisit`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting end time", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateVisitStatus = createAsyncThunk(
  "SalesRepresentative/UpdateVisitStatus",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesRepresentative/UpdateVisitStatus`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating status", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const insertVisitCompititors = createAsyncThunk(
  "SalesRepresentative/InsertVisitCompititors",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesRepresentative/InsertVisitCompititors`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting compititors", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateVisitCompititors = createAsyncThunk(
  "SalesRepresentative/UpdateVisitCompititors",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesRepresentative/UpdateVisitCompititors`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating compititors", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCompititorsBySRRetailerID = createAsyncThunk(
  "SalesRepresentative/FidnBYIdVisitCompititors",
  async (id: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesRepresentative/FidnBYIdVisitCompititors`,
        { sr_retailer_id: id }
      );
      const result = response.data?.Data as any;
      return result
        ? result?.map((compititor: any) => {
            return {
              compititor_id: compititor?.compititor_id,
              sr_retailer_id: compititor?.sr_retailer_id,
              ischeck: compititor?.ischeck ? compititor?.ischeck : false,
              item_group_id: compititor?.item_group_id
                ? compititor?.item_group_id
                    ?.split(",")
                    ?.map((group: any) => +group)
                : [],
              remarks: compititor?.remarks,
            };
          })
        : [];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching compititors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSelctedInSalesOrderWiseItemGroup = createAsyncThunk(
  "ItemGroup/FindAlItemlRetailerSalesOrder",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/ItemGroup/FindAlItemlRetailerSalesOrder`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching item group", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSalesReprsentativePDF = createAsyncThunk(
  "SalesRepresentative/FidnBYIdSalesReprsentativePDF",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesRepresentative/FidnBYIdSalesReprsentativePDF`,

        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching order details", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
