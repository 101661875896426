import React, { useEffect, useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  Avatar,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearEmployeeRegisterReportDetails,
  clearEmployeeRegisterReportList,
  setEmployeeRegisterReportDetails,
} from "../reportsSlice";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCellProps,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
// import { setCurrentPage, setPageLimit } from "../../pagination/paginationSlice";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
// import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import { clearItemList } from "../../Item/itemSlice";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { getEmployeeRegisterReport } from "../services/reports.services";
import { IEmployeeRegisterReportDetails } from "../reportsModel";
import { findAllActiveDepartment } from "../../department/services/department.services";
import { IDepartment } from "../../department/departmentModel";
import { checkAcessRights } from "../../../_helper/helper";
import { PRINT_ACCESS } from "../../../_contstants/common";
import { getAllEmployeeLocation } from "../../employee/services/employee.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DateChangeWatcher = ({ formRenderProps }: FormChangeWatcherProps) => {
  useEffect(() => {
    formRenderProps.onChange("department_id", {
      value: null,
    });
  }, []);
  return null;
};

const EmployeeRegisterReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const DepartmentList = useAppSelector(
    (state) => state.department.DepartmentList
  );
  const EmployeeRegisterReportList = useAppSelector(
    (state) => state.reports.EmployeeRegisterReportList
  );
  const EmployeeLocationList = useAppSelector(
    (state) => state.employee.EmployeeLocationList
  );
  const EmployeeRegisterReportDetails = useAppSelector(
    (state) => state.reports.EmployeeRegisterReportDetails
  );
  //   const currentPage = useAppSelector((state) => state.pagination.currentPage);
  //   const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    dispatch(findAllActiveDepartment());
    dispatch(getAllEmployeeLocation());
    return () => {
      dispatch(clearEmployeeRegisterReportDetails());
      dispatch(clearEmployeeRegisterReportList());
      //   dispatch(setCurrentPage(0));
      dispatch(clearItemList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      department_id: values.department_id,
      location: values.location,
      //   limit: +pageLimit,
      //   skip: +currentPage * +pageLimit,
    };
    dispatch(
      setEmployeeRegisterReportDetails({
        department_id: values.department_id,
        location: values.location,
      })
    );
    dispatch(getEmployeeRegisterReport(payload));
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={EmployeeRegisterReportDetails}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Employee Register Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="department_id"
                      label="Department"
                      placeholder="Select Department"
                      component={FormSelectionField}
                      options={DepartmentList.map((dept: IDepartment) => ({
                        value: dept.id,
                        label: dept.department_name,
                      }))}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="location"
                      label="Location"
                      placeholder="Select Location"
                      component={FormSelectionField}
                      options={EmployeeLocationList.map((loc: any) => ({
                        value: loc.location,
                        label: loc.location,
                      }))}
                    />
                  </GridLayoutItem>
                  <DateChangeWatcher formRenderProps={formRenderProps} />
                  <GridLayoutItem>
                    <div style={{ marginTop: "35px" }}>
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          EmployeeRegisterReportList &&
          EmployeeRegisterReportList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <EmployeeRegisterViewGridComponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const EmployeeRegisterViewGridComponent: React.FC = () => {
  const EmployeeRegisterReportList = useAppSelector(
    (state) => state.reports.EmployeeRegisterReportList
  );
  const EmployeeRegisterReportDetails = useAppSelector(
    (state) => state.reports.EmployeeRegisterReportDetails
  );

  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);

  const loading = useAppSelector((state) => state.retailer.loading);
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? EmployeeRegisterReportList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      // className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
      className={`table-header `}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  const handlePrint = (props: IEmployeeRegisterReportDetails, type: string) => {
    const state = {
      department_id: props.department_id,
      location: props.location,
    };

    if (type === "EmployeeRegisterReport") {
      sessionStorage.setItem("employeeRegisterState", JSON.stringify(state));
      const reportTab = window.open("/employeeregister", "_blank");
      const interval = setInterval(() => {
        if (reportTab?.closed) {
          sessionStorage.removeItem("employeeRegisterState");
          clearInterval(interval);
        }
      }, 1000);
    }
  };
  return (
    <>
      {EmployeeRegisterReportList && EmployeeRegisterReportList.length ? (
        <GridLayout
          //   style={{ marginRight: 30 }}
          gap={{ rows: 10, cols: 10 }}
          cols={[{ width: "100%" }]}
        >
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Employee Register Report
            </Typography.h4>
            <div>
              {isPrintAccess && (
                <Button
                  style={{ marginRight: 5 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={() =>
                    handlePrint(
                      EmployeeRegisterReportDetails,
                      "EmployeeRegisterReport"
                    )
                  }
                >
                  Print
                </Button>
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem>
            <KendoGrid
              style={{ maxHeight: "calc(100vh - 290px)" }}
              className="responsive-table"
              filterable={true}
              filter={filter}
              data={
                filter
                  ? filterBy(EmployeeRegisterReportList, filter).slice(
                      page.skip,
                      page.take + page.skip
                    )
                  : EmployeeRegisterReportList.slice(
                      page.skip,
                      page.take + page.skip
                    )
              }
              onFilterChange={handleFilterChange}
              skip={page.skip}
              take={page.take}
              total={EmployeeRegisterReportList.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [10, 25, 50, "All"],
                pageSizeValue: pageSizeValue,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <Column
                field="id"
                title="Sr. No."
                width={80}
                cell={(props: GridCellProps) => {
                  return <td>{props.dataIndex + 1}</td>;
                }}
              />
              <Column
                field="user_image"
                title="Image"
                width={100}
                filterable={false}
                cell={(props: any) => {
                  const firstName = props.dataItem.first_name
                    ? props.dataItem.first_name?.charAt(0)?.toUpperCase()
                    : "";
                  const lastName = props.dataItem.last_name
                    ? props.dataItem.last_name?.charAt(0)?.toUpperCase()
                    : "";
                  return (
                    <td>
                      <Avatar
                        className="employee-image"
                        style={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: props.dataItem.user_image
                            ? "white"
                            : "#303F9F",
                        }}
                      >
                        {props.dataItem.user_image ? (
                          <img
                            src={props.dataItem.user_image}
                            alt={`${firstName + lastName}`}
                            style={{
                              height: "100%",
                            }}
                          />
                        ) : (
                          `${firstName + lastName}`
                        )}
                      </Avatar>
                    </td>
                  );
                }}
              />
              <Column
                field="first_name"
                title="Employee Name"
                width={300}
                cell={(props: GridCellProps) => {
                  return (
                    <td>{`${props.dataItem.first_name} ${props.dataItem.middle_name} ${props.dataItem.last_name}`}</td>
                  );
                }}
              />
              <Column field="gender" title="Gender" width={100} />
              <Column field="dob" title="Date of Birth" width={150} />
              <Column field="nationality" title="Nationality" width={120} />
              <Column
                field="date_of_joining"
                title="Date Of Joining"
                width={150}
              />
              <Column
                field="designation_name"
                title="Designation"
                width={150}
              />
              <Column field="mobile_no" title="Mobile No" width={150} />
              <Column field="uan_no" title="UAN No" width={150} />
              <Column field="pan_card" title="PAN No" width={150} />
              <Column field="adharcard" title="Aadhar No" width={150} />
              <Column field="bank_name" title="Bank Name" width={200} />
              <Column field="account_no" title="Account No" width={150} />
              <Column field="bank_branch" title="Bank Branch" width={150} />
              <Column field="bank_branch" title="Bank Branch" width={150} />
              <Column field="ifsc_code" title="IFSC Code" width={150} />

              <Column
                field="address"
                title="Present Address"
                cell={(props: GridCellProps) => (
                  <td>
                    {[
                      props?.dataItem?.address,
                      props?.dataItem?.street,
                      props?.dataItem?.area,
                      props?.dataItem?.city
                        ? `${props?.dataItem.city}-${props?.dataItem.pincode}`
                        : "",
                      props?.dataItem?.state_name,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </td>
                )}
                width={200}
              />

              <Column
                field="permenent_address"
                title="Permanent Address"
                cell={(props: GridCellProps) => (
                  <td>
                    {[
                      props?.dataItem?.permenent_address,
                      props?.dataItem?.permenent_street,
                      props?.dataItem?.permenent_area,
                      props?.dataItem?.permenent_city
                        ? `${props?.dataItem.permenent_city}${
                            props?.dataItem.permenent_pincode
                              ? `-${props?.dataItem.permenent_pincode}`
                              : ""
                          }`
                        : "",
                      props?.dataItem?.permenent_state_name,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </td>
                )}
                width={200}
              />

              <Column field="left_date" title="Left Date" width={150} />
              <Column
                field="reason_of_exit"
                title="Reason Of Exit"
                width={250}
              />
            </KendoGrid>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default EmployeeRegisterReportForm;
