// import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../components/layout/Layout";
// import LogIn from "../features/login/Login";
import Country from "../features/country/Country";
import User from "../features/user/User";
import CreateUser from "../features/user/CreateUser";
import UserType from "../features/userType/UserType";
import Menu from "../features/menu/Menu";
import Rights from "../features/rights/Rights";
import State from "../features/state/State";
import City from "../features/city/City";
import CreateUserType from "../features/userType/CreateUserType";
import Dashboard from "../features/dashboard/Dashboard";
import PurchaseOrder from "../features/purchaseorder/PurchaseOrder";
import CreatePurchaseOrder from "../features/purchaseorder/CreatePurchaseOrder";
import ItemCategory from "../features/Item Category/ItemCategory";
import Gst from "../features/gst/Gst";
import ItemGroup from "../features/ItemGroup/ItemGroup";
import Unit from "../features/unit/Unit";
import Machine from "../features/machine/Machine";
import Item from "../features/Item/Item";
import CreateItem from "../features/Item/CreateItem";
import WareHouse from "../features/warehouse/WareHouse";
import Inward from "../features/inward/Inward";
import CreateInward from "../features/inward/CreateInward";
import InwardReturn from "../features/inwardreturn/InwardReturn";
import CreateInwardReturn from "../features/inwardreturn/CreateInwardReturn";
import MagnetCleaning from "../features/magnetcleaning/MagnetCleaning";
import CreateMagnetCleaning from "../features/magnetcleaning/CreateMagnetCleaning";
import MachineCleaning from "../features/machinecleaning/MachineCleaning";
import CreateMachineCleaning from "../features/machinecleaning/CreateMachineCleaning";
import Account from "../features/account/Account";
import CreateAccount from "../features/account/CreateAccount";
import Process from "../features/process/Process";
import ProductCleaning from "../features/productcleaning/ProductCleaning";
import CreateProductCleaning from "../features/productcleaning/CreateProductCleaning";
import Department from "../features/department/Department";
import Bom from "../features/bom/Bom";
import CreateBom from "../features/bom/CreateBom";
import ProductMilling from "../features/productmilling/ProductMilling";
import CreateProductMilling from "../features/productmilling/CreateProductMilling";
import Roasting from "../features/roasting/Roasting";
import CreateRoasting from "../features/roasting/CreateRoasting";
import Production from "../features/production/Production";
import ProductMillingReportForm from "../features/reports/reportsform/ProductMillingReportForm";
import ProductionReportForm from "../features/reports/reportsform/ProductionReportForm";
import GhantiCleaningReportForm from "../features/reports/reportsform/GhantiCleaningReportForm";
import MetalCleaningReportForm from "../features/reports/reportsform/MetalCleaningReportForm";
import ProductCleaningReportForm from "../features/reports/reportsform/ProductCleaningReportForm";
import RoastingReportForm from "../features/reports/reportsform/RoastingReportForm";
import InwardPackingMaterial from "../features/inwardpacking/InwardPackingMaterial";
import CreateInwardPackingMaterial from "../features/inwardpacking/CreateInwardPackingMaterial";
import OutwardPackingMaterial from "../features/outwardpacking/OutwardPackingMaterial";
import CreateOutwardPackingMaterial from "../features/outwardpacking/CreateOutwardPackingMaterial";
import StockTransfer from "../features/stocktransfer/StockTransfer";
import CreateStockTransfer from "../features/stocktransfer/CreateStockTransfer";
import InwardPackingMaterialStockForm from "../features/reports/reportsform/InwardPakingMaterialStockForm";
import OutwardPackingMaterialStockForm from "../features/reports/reportsform/OutwardPakingMaterialStockForm";
import { PrivateRoute } from "./PrivateRoute";
import InwardRegisterReportForm from "../features/reports/reportsform/InwardRegisterReportForm";
import Packing from "../features/packing/Packing";
import CreatePacking from "../features/packing/CreatePacking";
import StockReportForm from "../features/reports/reportsform/StockReportForm";
import PackingRegisterReport from "../features/reports/reportsform/PackingRegisterReport";
// import PackingMaterialStockReport from "../features/reports/reportsform/PackingMaterialStockReport";
import SalesOrder from "../features/salesorder/SalesOrder";
import CreateSalesOrder from "../features/salesorder/CreateSalesOrder";
import SalesOrderPrint from "../features/salesorder/SalesOrderPrint";
import OrderForm from "../features/placeorder/OrderForm";
import Visitor from "../features/visitor/Visitor";
import CreateVisitor from "../features/visitor/CreateVisitor";
import OfficeStaffOut from "../features/officestaffout/OfficeStaffOut";
import CreateOfficeStaffOut from "../features/officestaffout/CreateOfficeStaffOut";
import VisitorReportForm from "../features/reports/reportsform/VisitorReportForm";
import OfficeStaffOutReportForm from "../features/reports/reportsform/OfficeStaffOutReportForm";
import OrderHistory from "../features/placeorder/OrderHistory";
import Sales from "../features/sales/Sales";
import CreateSales from "../features/sales/CreateSales";
import SalesPrint from "../features/sales/SalesPrint";
import AccountLayout from "../components/layout/AccountLayout";
import AccountDashboard from "../features/dashboard/AccountDashboard";
import { useAppSelector } from "../app/hooks";
import CreateItemGroup from "../features/ItemGroup/CreateItemGroup";
import VisitorPrint from "../features/visitor/VisitorPrint";
import SalesChallan from "../features/saleschallan/SalesChallan";
import CreateSalesChallan from "../features/saleschallan/CreateSalesChallan";
import GatePassPrint from "../features/sales/GatePassPrint";
import EnvelopePrint from "../features/sales/EnvelopePrint";
import SalesChallanPrint from "../features/saleschallan/SalesChallanPrint";
import Employee from "../features/employee/Employee";
import CreateEmployee from "../features/employee/CreateEmployee";
import Designation from "../features/designation/Designation";
import Document from "../features/document/Document";
import Relation from "../features/relation/Relation";
import Holiday from "../features/holiday/Holiday";
import Leave from "../features/leave/Leave";
import CreateProfessionTaxSlab from "../features/ProfessionTaxSlab/CreateProfessionTaxSlab";
import DistributorOpeningStock from "../features/distributoropeningstock/DistributorOpeningStock";
import CreateDistributorOpeningStock from "../features/distributoropeningstock/CreateDistributorOpeningStock";
import Retailer from "../features/retailer/Retailer";
import CreateRetailer from "../features/retailer/CreateRetailer";
import RetailerSalesOrder from "../features/retailersalesorder/RetailerSalesOrder";
import CreateRetailerSalesOrder from "../features/retailersalesorder/CreateRetailerSalesOrder";
import CreateEmployeeLeave from "../features/employeeLeave/CreateEmployeeLeave";
import AdminEmployeeLeave from "../features/employeeLeave/AdminEmployeeLeave";
import Purchase from "../features/purchase/Purchase";
import CreatePurchase from "../features/purchase/CreatePurchase";
import PurchaseDueDateInvoiceReportForm from "../features/reports/reportsform/PurchaseDueDateInvoiceReportForm";
import Processpayroll from "../features/processpayroll/Processpayroll";
import SalaryGeneration from "../features/salarygeneration/SalaryGeneration";
import District from "../features/district/District";
import RouteMaster from "../features/route/RouteMaster";
import PurchaseOrderPrint from "../features/purchaseorder/PurchaseOrderPrint";
import SalaryGenerationView from "../features/salarygeneration/SalaryGenerationView";
import TermsAndCondition from "../features/termsandcondition/TermsAndCondition";
import DriverLayout from "../components/layout/DriverLayout";
import DriverDashboard from "../features/dashboard/DriverDashboard";
import DriverVisitHistory from "../features/dashboard/DriverVisitHistory";
import DriverRouteVisitView from "../features/drivervisit/DriverRouteVisitView";
import DriverVisit from "../features/drivervisit/DriverVisit";
import CreateDriverVisit from "../features/drivervisit/CreateDriverVisit";
import DriverVisitVoucherPrint from "../features/drivervisit/DriverVisitVoucherPrint";
import CreateOldDistributorData from "../features/olddistributordata/CreateOldDistributorData";
import DistributorCurrentStock from "../features/distributorcurrentstock/DistributorCurrentStock";
import NewLogin from "../features/login/NewLogin";
import CreateProduction2 from "../features/production/CreateProduction2";
import RetailerRateReport from "../features/reports/reportsform/RetailerRateReport";
import AccountGroup from "../features/accountgroup/AccountGroup";
import PORegisterReportForm from "../features/reports/reportsform/PORegisterReportForm";
import PurchaseRegisterReportForm from "../features/reports/reportsform/PurchaseRegisterReportForm";
import SalesRegisterReportForm from "../features/reports/reportsform/SalesRegisterReportForm";
import SalaryGenerationViewUpdated from "../features/salarygeneration/SalaryGenerationViewUpdated";
import SalaryRegisterReport from "../features/reports/reportsform/SalaryRegisterReport";
import SalaryRegisterReportForm from "../features/reports/reportsform/SalaryRegisterReportForm";
import SalarySlips from "../features/reports/reportsform/SalarySlips";
import Scheme from "../features/scheme/Scheme";
import CreateScheme from "../features/scheme/CreateScheme";
import Area from "../features/area/Area";
import SalaryRegisterReportFormWithExcel from "../features/reports/reportsform/SalaryRegisterReportFormWithExcel";
import EmployeeDetailsReportForm from "../features/reports/reportsform/EmployeeDetailsReportForm";
import EmployeeRegister from "../features/reports/reportsform/EmployeeRegister";
import EmployeeRegisterReportForm from "../features/reports/reportsform/EmployeeRegisterReportForm";
import WageRegisterReport from "../features/reports/reportsform/WageRegisterReport";
import SchemeReportForm from "../features/reports/reportsform/SchemeReportForm";
import LeaveWithWagesCardReport from "../features/reports/reportsform/LeaveWithWagesCardReport";
import Setting from "../features/setting/Setting";
import AccountReportForm from "../features/reports/reportsform/AccountReportForm";
import WageRegisterReportForm from "../features/reports/reportsform/WageRegisterReportForm";
import LeaveWithWagesCardReportForm from "../features/reports/reportsform/LeaveWithWagesCardReportForm";

import CreateStockTransferMulti from "../features/stocktransfermulti/CreateStockTransferMulti";
import StockTransferMulti from "../features/stocktransfermulti/StockTransferMulti";
import RegisterOfLeaveWithWagesForm from "../features/reports/reportsform/RegisterOfLeaveWithWagesForm";
import FormFNominationForm from "../features/reports/reportsform/FormFNominationReport";
import NominationFormReport from "../features/reports/reportsform/NominationFormReport";
import DebitNote from "../features/debitNote/DebitNote";
import CreditNote from "../features/creditNote/CreditNote";
import CreateDebitNote from "../features/debitNote/CreateDebitNote";
import EmployeeProvidentFundOrganizationForm from "../features/reports/reportsform/EmployeeProvidentFundOrganizationForm";
import EmployeeProvidentFundReport from "../features/reports/reportsform/EmployeeProvidentFundReport";
import CreateCreditNote from "../features/creditNote/CreateCreditNote";
import PackingStockReportForm from "../features/reports/reportsform/PackingStockReportForm";
import NominationAndDeclarationForm from "../features/reports/reportsform/NominationAndDeclarationForm";
import NominationAndDeclarationview from "../features/reports/reportsform/NominationAndDeclarationview";
import CreatePayment from "../features/payment/CreatePayment";
import FormNo29 from "../features/reports/reportsform/FormNo29";
import FormNo35 from "../features/reports/reportsform/FormNo35";
import FormNo35Report from "../features/reports/reportsform/FormNo35Report";
import FormAview from "../features/reports/reportsform/FormAview";
import Form5view from "../features/reports/reportsform/Form5view";
import FormNo15 from "../features/reports/reportsform/FormNo15";
import Payment from "../features/payment/Payment";
import DebitNoteRegisterReportForm from "../features/reports/reportsform/DebitNoteRegisterReportForm";
import Receipt from "../features/receipt/Receipt";
import CreateReceipt from "../features/receipt/CreateReceipt";

import Competitors from "../features/competition/Competitors";
import CreditNoteRegisterReportForm from "../features/reports/reportsform/CreditNoteRegisterReportForm";
import AreaAssignToDistributor from "../features/areaAssignToDistributor/AreaAssignToDistributor";
import CreateAreaAssignToDistributor from "../features/areaAssignToDistributor/CreateAreaAssignToDistributor";
import CreateSalesRepresentativeAssign from "../features/salesrepresentativeassign/CreateSalesRepresentativeAssign";
import SalesRepresentative from "../features/salesrepresentativeassign/SalesRepresentative";
import VoucherType from "../features/vouchertype/VoucherType";
import SRLayout from "../components/layout/SRLayout";
import SRDashboard from "../features/dashboard/SRDashboard";
import DistributorOrder from "../features/distributororder/DistributorOrder";
import DistributorCompititor from "../features/distributororder/DistributorCompititor";
import LoanEntry from "../features/loanentry/Loanentry";
import CreateLoanEntry from "../features/loanentry/CreateLoanentry";
import SRdistributorOrderView from "../features/distributororder/SRdistributorOrderView";
import DownloadOrderView from "../features/distributororder/DownloadOrderView";

const MainRouter = () => {
  const AccountIDString = useAppSelector(
    (state) => state.login.loginDetails.AccountID
  );
  const UserIDString = useAppSelector(
    (state) => state.login.loginDetails.UserID
  );
  const UserTypeIDString = useAppSelector(
    (state) => state.login.loginDetails.UserTypeID
  );
  const AccountID = parseInt(AccountIDString);
  const UserID = parseInt(UserIDString);
  const UserTypeID = parseInt(UserTypeIDString);

  return (
    <Routes>
      <Route path="login" element={<NewLogin />} />
      {/* <Route path="login" element={<LogIn />} /> */}
      <Route path="salaryregisterreport" element={<SalaryRegisterReport />} />
      <Route path="salaryslips" element={<SalarySlips />} />
      <Route path="employeeregister" element={<EmployeeRegister />} />
      <Route path="wageregister" element={<WageRegisterReport />} />
      <Route path="leavewtihwages" element={<LeaveWithWagesCardReport />} />
      <Route
        path="salesorderprint/:sale_order_guid"
        element={<SalesOrderPrint />}
      />
      <Route path="salesprint/:sale_guid" element={<SalesPrint />} />
      <Route
        path="purchaseorderprint/:purchase_order_guid"
        element={<PurchaseOrderPrint />}
      />
      <Route path="sales/gatepass/:sale_guid" element={<GatePassPrint />} />
      <Route path="sales/envelope/:sale_guid" element={<EnvelopePrint />} />
      <Route path="visitorprint/:visitor_guid" element={<VisitorPrint />} />
      <Route
        path="saleschallanprint/:sales_challan_guid"
        element={<SalesChallanPrint />}
      />
      <Route
        path="drivervisitprint/:id"
        element={<DriverVisitVoucherPrint />}
      />
      <Route path="formfnomination" element={<NominationFormReport />} />
      <Route
        path="employeesprovidentfund"
        element={<EmployeeProvidentFundOrganizationForm />}
      />
      <Route
        path="nominationandeclarationform"
        element={<NominationAndDeclarationForm />}
      />

      <Route path="formno35" element={<FormNo35 />} />

      <Route path="formno29" element={<FormNo29 />} />
      <Route path="formAview" element={<FormAview />} />
      <Route path="form5view" element={<Form5view />} />
      <Route path="formno15view" element={<FormNo15 />} />
      <Route path="downloadorderview" element={<DownloadOrderView />} />

      {AccountID && (
        <Route
          path="/"
          element={
            <PrivateRoute>
              <AccountLayout />
            </PrivateRoute>
          }
        >
          <Route index={true} element={<AccountDashboard />} />
          <Route path="order" element={<OrderForm />} />
          <Route path="order/edit" element={<OrderForm />} />
          <Route path="orderhistory" element={<OrderHistory />} />
          <Route path="retailersalesorder" element={<RetailerSalesOrder />} />
          <Route
            path="retailersalesorder/create"
            element={<CreateRetailerSalesOrder />}
          />
          <Route
            path="retailersalesorder/edit"
            element={<CreateRetailerSalesOrder />}
          />
        </Route>
      )}
      {UserID && UserTypeID === 4 ? (
        <Route
          path="/"
          element={
            <PrivateRoute>
              <DriverLayout />
            </PrivateRoute>
          }
        >
          <Route index={true} element={<DriverDashboard />} />
          <Route path="driverhistory" element={<DriverVisitHistory />} />
          <Route path="drivervisit/view" element={<DriverRouteVisitView />} />
        </Route>
      ) : UserID && UserTypeID === 5 ? (
        <Route
          path="/"
          element={
            <PrivateRoute>
              <SRLayout />
            </PrivateRoute>
          }
        >
          <Route index={true} element={<SRDashboard />} />
          <Route path="order" element={<OrderForm />} />
          <Route path="order/edit" element={<OrderForm />} />
          <Route path="orderhistory" element={<OrderHistory />} />
          <Route path="retailersalesorder" element={<RetailerSalesOrder />} />
          <Route
            path="retailersalesorder/create"
            element={<CreateRetailerSalesOrder />}
          />
          <Route
            path="retailersalesorder/edit"
            element={<CreateRetailerSalesOrder />}
          />
          <Route
            path="retailersalesorder/view"
            element={<SRdistributorOrderView />}
          />
          <Route path="distributororder" element={<DistributorOrder />} />
          <Route
            path="distributororder/compititor/add"
            element={<DistributorCompititor />}
          />
          <Route
            path="distributororder/compititor/edit"
            element={<DistributorCompititor />}
          />
        </Route>
      ) : (
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route index={true} element={<Dashboard />} />
          <Route path="user" element={<User />} />
          <Route path="user/create" element={<CreateUser />} />
          <Route path="user/edit" element={<CreateUser />} />
          <Route path="employee" element={<Employee />} />
          <Route path="employee/create" element={<CreateEmployee />} />
          <Route path="employee/edit" element={<CreateEmployee />} />
          <Route path="usertype" element={<UserType />} />
          <Route path="usertype/create" element={<CreateUserType />} />
          <Route path="usertype/edit" element={<CreateUserType />} />
          <Route path="menu" element={<Menu />} />
          <Route path="rights" element={<Rights />} />
          <Route path="country" element={<Country />} />
          <Route path="state" element={<State />} />
          <Route path="city" element={<City />} />
          <Route path="routes" element={<RouteMaster />} />
          <Route path="district" element={<District />} />
          <Route path="designation" element={<Designation />} />
          <Route path="document" element={<Document />} />
          <Route path="relation" element={<Relation />} />
          <Route path="holiday" element={<Holiday />} />
          <Route path="leave" element={<Leave />} />
          <Route path="competitors" element={<Competitors />} />

          <Route
            path="professiontaxslab"
            element={<CreateProfessionTaxSlab />}
          />
          <Route path="processpayroll" element={<Processpayroll />} />
          <Route path="salarygeneration" element={<SalaryGeneration />} />
          <Route
            path="salarygenerationview"
            element={<SalaryGenerationView />}
          />
          <Route
            path="salarygenerationviewupdated"
            element={<SalaryGenerationViewUpdated />}
          />
          <Route path="employeeleave" element={<AdminEmployeeLeave />} />
          <Route
            path="employeeleave/create"
            element={<CreateEmployeeLeave />}
          />
          <Route path="employeeleave/edit" element={<CreateEmployeeLeave />} />
          <Route path="purchaseorder" element={<PurchaseOrder />} />
          <Route
            path="purchaseorder/create"
            element={<CreatePurchaseOrder />}
          />
          <Route path="purchaseorder/edit" element={<CreatePurchaseOrder />} />
          <Route path="inward" element={<Inward />} />
          <Route path="inward/create" element={<CreateInward />} />
          <Route path="inward/edit" element={<CreateInward />} />
          <Route path="inwardreturn" element={<InwardReturn />} />
          <Route path="inwardreturn/create" element={<CreateInwardReturn />} />
          <Route path="inwardreturn/edit" element={<CreateInwardReturn />} />
          <Route path="productcleaning" element={<ProductCleaning />} />
          <Route
            path="productcleaning/create"
            element={<CreateProductCleaning />}
          />
          <Route
            path="productcleaning/edit"
            element={<CreateProductCleaning />}
          />
          <Route path="production" element={<Production />} />
          {/* <Route path="production/create" element={<CreateProduction />} />
          <Route path="production/edit" element={<CreateProduction />} /> */}
          <Route path="production/create" element={<CreateProduction2 />} />
          <Route path="production/edit" element={<CreateProduction2 />} />
          <Route path="itemcategory" element={<ItemCategory />} />
          <Route path="gst" element={<Gst />} />
          <Route path="itemgroup" element={<ItemGroup />} />
          <Route path="itemgroup/create" element={<CreateItemGroup />} />
          <Route path="itemgroup/edit" element={<CreateItemGroup />} />
          <Route path="unit" element={<Unit />} />
          <Route path="machine" element={<Machine />} />
          <Route path="item" element={<Item />} />
          <Route path="item/create" element={<CreateItem />} />
          <Route path="item/edit" element={<CreateItem />} />
          <Route path="rawmaterialitem" element={<Item />} />
          <Route path="rawmaterialitem/create" element={<CreateItem />} />
          <Route path="rawmaterialitem/edit" element={<CreateItem />} />
          <Route path="semifinisheditem" element={<Item />} />
          <Route path="semifinisheditem/create" element={<CreateItem />} />
          <Route path="semifinisheditem/edit" element={<CreateItem />} />
          <Route path="finisheditem" element={<Item />} />
          <Route path="finisheditem/create" element={<CreateItem />} />
          <Route path="finisheditem/edit" element={<CreateItem />} />
          <Route path="packingmaterialitem" element={<Item />} />
          <Route path="packingmaterialitem/create" element={<CreateItem />} />
          <Route path="packingmaterialitem/edit" element={<CreateItem />} />
          <Route path="warehouse" element={<WareHouse />} />
          <Route path="magnetcleaning" element={<MagnetCleaning />} />
          <Route
            path="magnetcleaning/create"
            element={<CreateMagnetCleaning />}
          />
          <Route
            path="magnetcleaning/edit"
            element={<CreateMagnetCleaning />}
          />
          <Route path="machinecleaning" element={<MachineCleaning />} />
          <Route
            path="machinecleaning/create"
            element={<CreateMachineCleaning />}
          />
          <Route
            path="machinecleaning/edit"
            element={<CreateMachineCleaning />}
          />
          <Route path="account" element={<Account />} />
          <Route path="account/create" element={<CreateAccount />} />
          <Route path="account/edit" element={<CreateAccount />} />
          <Route
            path="account/adddistributordata"
            element={<CreateOldDistributorData />}
          />
          <Route path="process" element={<Process />} />
          <Route path="department" element={<Department />} />
          <Route path="bom" element={<Bom />} />
          <Route path="bom/create" element={<CreateBom />} />
          <Route path="bom/edit" element={<CreateBom />} />
          <Route path="productmilling" element={<ProductMilling />} />
          <Route
            path="productmilling/create"
            element={<CreateProductMilling />}
          />
          <Route
            path="productmilling/edit"
            element={<CreateProductMilling />}
          />
          <Route path="roasting" element={<Roasting />} />
          <Route path="roasting/create" element={<CreateRoasting />} />
          <Route path="roasting/edit" element={<CreateRoasting />} />
          <Route
            path="inwardpackingmaterial"
            element={<InwardPackingMaterial />}
          />
          <Route
            path="inwardpackingmaterial/create"
            element={<CreateInwardPackingMaterial />}
          />
          <Route
            path="inwardpackingmaterial/edit"
            element={<CreateInwardPackingMaterial />}
          />
          <Route
            path="outwardpackingmaterial"
            element={<OutwardPackingMaterial />}
          />
          <Route
            path="outwardpackingmaterial/create"
            element={<CreateOutwardPackingMaterial />}
          />
          <Route
            path="outwardpackingmaterial/edit"
            element={<CreateOutwardPackingMaterial />}
          />
          <Route path="stocktransfer" element={<StockTransfer />} />
          <Route
            path="stocktransfer/create"
            element={<CreateStockTransfer />}
          />
          <Route path="stocktransfer/edit" element={<CreateStockTransfer />} />

          <Route path="stocktransfermulti" element={<StockTransferMulti />} />
          <Route
            path="stocktransfermulti/create"
            element={<CreateStockTransferMulti />}
          />
          <Route
            path="stocktransfermulti/edit"
            element={<CreateStockTransferMulti />}
          />
          <Route path="packing" element={<Packing />} />
          <Route path="packing/create" element={<CreatePacking />} />
          <Route path="packing/edit" element={<CreatePacking />} />
          <Route
            path="productmillingreportview"
            element={<ProductMillingReportForm />}
          />
          <Route
            path="productionreportview"
            element={<ProductionReportForm />}
          />
          <Route
            path="ghanticleaningreportview"
            element={<GhantiCleaningReportForm />}
          />
          <Route
            path="metalcleaningreportview"
            element={<MetalCleaningReportForm />}
          />
          <Route
            path="productcleaningreportview"
            element={<ProductCleaningReportForm />}
          />
          <Route
            path="inwardpackingstockreportview"
            element={<InwardPackingMaterialStockForm />}
          />
          <Route
            path="outwordpackingstockreportview"
            element={<OutwardPackingMaterialStockForm />}
          />
          <Route
            path="inwardregisterreportview"
            element={<InwardRegisterReportForm />}
          />
          <Route path="stockreportview" element={<StockReportForm />} />
          <Route
            path="packingregisterreportview"
            element={<PackingRegisterReport />}
          />
          {/* <Route
            path="packingmaterialstockreportview"
            element={<PackingMaterialStockReport />}
          /> */}
          <Route path="visitorreportview" element={<VisitorReportForm />} />
          <Route
            path="officestaffoutreportview"
            element={<OfficeStaffOutReportForm />}
          />
          <Route
            path="purchaseduedatereportview"
            element={<PurchaseDueDateInvoiceReportForm />}
          />
          <Route
            path="purchaseregisterview"
            element={<PurchaseRegisterReportForm />}
          />
          <Route
            path="salesregisterview"
            element={<SalesRegisterReportForm />}
          />
          <Route
            path="debitnoteregisterview"
            element={<DebitNoteRegisterReportForm />}
          />
          <Route
            path="area-assign-to-distributor"
            element={<AreaAssignToDistributor />}
          />
          <Route
            path="area-assign/edit"
            element={<CreateAreaAssignToDistributor />}
          />
          <Route
            path="creditnoteregisterview"
            element={<CreditNoteRegisterReportForm />}
          />
          <Route
            path="salaryregisterview"
            element={<SalaryRegisterReportForm />}
          />
          <Route path="wageregisterview" element={<WageRegisterReportForm />} />
          <Route
            path="salaryregisterexcel"
            element={<SalaryRegisterReportFormWithExcel />}
          />
          <Route
            path="employeedetailsview"
            element={<EmployeeDetailsReportForm />}
          />
          <Route
            path="employeeregisterview"
            element={<EmployeeRegisterReportForm />}
          />
          <Route
            path="leavewithwagesview"
            element={<LeaveWithWagesCardReportForm />}
          />
          <Route
            path="registerofleavewithwagesview"
            element={<RegisterOfLeaveWithWagesForm />}
          />
          <Route path="formfnominationview" element={<FormFNominationForm />} />
          <Route
            path="nominationanddeclarationview"
            element={<NominationAndDeclarationview />}
          />
          <Route path="formno35view" element={<FormNo35Report />} />
          <Route
            path="providentfundformview"
            element={<EmployeeProvidentFundReport />}
          />
          <Route path="roastingreportview" element={<RoastingReportForm />} />
          <Route path="salesorder" element={<SalesOrder />} />
          <Route path="salesorder/create" element={<CreateSalesOrder />} />
          <Route path="salesorder/edit" element={<CreateSalesOrder />} />
          <Route path="sales" element={<Sales />} />
          <Route path="sales/create" element={<CreateSales />} />
          <Route path="sales/edit" element={<CreateSales />} />
          <Route path="visitor" element={<Visitor />} />
          <Route path="visitor/create" element={<CreateVisitor />} />
          <Route path="visitor/edit" element={<CreateVisitor />} />
          <Route path="officestaffout" element={<OfficeStaffOut />} />
          <Route
            path="officestaffout/create"
            element={<CreateOfficeStaffOut />}
          />
          <Route
            path="officestaffout/edit"
            element={<CreateOfficeStaffOut />}
          />
          <Route path="saleschallan" element={<SalesChallan />} />
          <Route path="saleschallan/create" element={<CreateSalesChallan />} />
          <Route path="saleschallan/edit" element={<CreateSalesChallan />} />
          <Route
            path="distributoropeningstock"
            element={<DistributorOpeningStock />}
          />
          <Route
            path="distributoropeningstock/create"
            element={<CreateDistributorOpeningStock />}
          />
          <Route
            path="distributoropeningstock/edit"
            element={<CreateDistributorOpeningStock />}
          />
          <Route path="retailersalesorder" element={<RetailerSalesOrder />} />
          <Route
            path="retailersalesorder/create"
            element={<CreateRetailerSalesOrder />}
          />
          <Route
            path="retailersalesorder/edit"
            element={<CreateRetailerSalesOrder />}
          />
          <Route path="retailer" element={<Retailer />} />
          <Route path="retailer/create" element={<CreateRetailer />} />
          <Route path="retailer/edit" element={<CreateRetailer />} />
          <Route path="purchase" element={<Purchase />} />
          <Route path="purchase/create" element={<CreatePurchase />} />
          <Route path="purchase/edit" element={<CreatePurchase />} />
          <Route path="debitnote" element={<DebitNote />} />
          <Route path="debitnote/create" element={<CreateDebitNote />} />
          <Route path="debitnote/edit" element={<CreateDebitNote />} />
          <Route path="creditnote" element={<CreditNote />} />
          <Route path="creditnote/create" element={<CreateCreditNote />} />
          <Route path="creditnote/edit" element={<CreateCreditNote />} />
          <Route path="termandcondition" element={<TermsAndCondition />} />
          <Route path="drivervisit" element={<DriverVisit />} />
          <Route path="drivervisit/edit" element={<CreateDriverVisit />} />
          <Route path="drivervisit/view" element={<DriverRouteVisitView />} />
          <Route path="accountgroup" element={<AccountGroup />} />
          <Route
            path="distributorcurrentstock"
            element={<DistributorCurrentStock />}
          />
          <Route path="retailerpricereport" element={<RetailerRateReport />} />
          <Route
            path="poregisterreportview"
            element={<PORegisterReportForm />}
          />
          <Route
            path="packingmaterialstockreportview"
            element={<PackingStockReportForm />}
          />

          <Route path="payment" element={<Payment />} />
          <Route path="payment/create" element={<CreatePayment />} />
          <Route path="payment/edit" element={<CreatePayment />} />
          <Route path="receipt" element={<Receipt />} />
          <Route path="receipt/create" element={<CreateReceipt />} />
          <Route path="receipt/edit" element={<CreateReceipt />} />
          <Route path="scheme" element={<Scheme />} />
          <Route path="scheme/create" element={<CreateScheme />} />
          <Route path="scheme/edit" element={<CreateScheme />} />
          <Route path="area" element={<Area />} />
          <Route path="loanentry" element={<LoanEntry />} />
          <Route path="loanentry/create" element={<CreateLoanEntry />} />
          <Route path="loanentry/edit" element={<CreateLoanEntry />} />
          <Route path="schemereportview" element={<SchemeReportForm />} />
          <Route path="setting" element={<Setting />} />
          <Route path="accountreportview" element={<AccountReportForm />} />
          <Route
            path="salesrepresentativeschedule"
            element={<SalesRepresentative />}
          />
          <Route
            path="salesrepresentativeschedule/create"
            element={<CreateSalesRepresentativeAssign />}
          />
          <Route path="vouchertype" element={<VoucherType />} />
          <Route path="*" element={<h1>404</h1>} />
        </Route>
      )}
    </Routes>
  );
};

export default MainRouter;
