import React, { useState, useEffect } from "react";
import AppDialog from "../../components/dialog/Dialog";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";

const SearchMenu: React.FC = () => {
  const RemoveKey = [
    "dashboard",
    "usermanagement",
    "masters",
    "purchasemenu",
    "productionmenu",
    "salesmenu",
    "visitormenu",
    "packingmaterialmenu",
    "hrmenu",
    "drivermenu",
  ];
  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let menuList: {
    menu_id: number;
    menu_key: string;
    menu_name: string;
    rights_id: string;
  }[];

  try {
    menuList = JSON.parse(userResponseString).filter(
      (item: any) => !RemoveKey.includes(item.menu_key)
    );
  } catch (error) {
    menuList = [];
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMenus, setFilteredMenus] = useState<any>(null);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const dialogName = useAppSelector(
    (state: RootState) => state.dialog.dialogName
  );
  const navigate = useNavigate();

  useEffect(() => {
    setFilteredMenus(menuList);
  }, [menuList?.map((item: any) => item?.menu_id).join("-")]);
  // Handle Ctrl + K key press
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === "k" && location.pathname !== "/login") {
        e.preventDefault();
        dispatch(openDialog("SearchMenuDialog"));
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      dispatch(closeDialog());
      setFilteredMenus([]);
    };
  }, []);
  useEffect(() => {
    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      setFilteredMenus(
        menuList.filter(
          (menu: any) =>
            menu.menu_name.toLowerCase().includes(lowercasedTerm) ||
            menu.menu_key.toLowerCase().includes(lowercasedTerm)
        )
      );
    } else {
      setFilteredMenus(menuList);
    }
  }, [searchTerm]);

  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };

  const handleNavigate = (key: any) => {
    navigate(key);
    dispatch(closeDialog());
    setSearchTerm("");
  };
  return (
    <>
      {dialogName === "SearchMenuDialog" && location.pathname !== "/login" && (
        <AppDialog>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleCloseDialog}
          >
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "8px",
                height: "400px",
                width: "600px",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <h2>Search</h2>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search menu..."
                className="global-search-input"
              />
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  maxHeight: "70%",
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  WebkitOverflowScrolling: "touch",
                }}
              >
                <ul>
                  {filteredMenus.map((menu: any) => (
                    <Tooltip
                      key={menu.key}
                      anchorElement="target"
                      position="top"
                      parentTitle={true}
                    >
                      <button
                        key={menu.menu_id}
                        className="global-search-button"
                        onClick={() => handleNavigate(menu.menu_key)}
                      >
                        {menu.menu_name}
                      </button>
                    </Tooltip>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </AppDialog>
      )}
    </>
  );
};

export default SearchMenu;
