import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CurrentDateWiseDistributor,
  IDistributorOrderState,
  IDistributorWiseRetailerList,
} from "./distributorOrderModel";
import {
  getAllCurrentDateAndDistributorWiseRetailer,
  getAllCurrentDateWiseDistributor,
  getCompititorsBySRRetailerID,
  getSalesReprsentativePDF,
  getSelctedInSalesOrderWiseItemGroup,
  insertEndDistributorVisit,
  insertStartDistributorVisit,
  insertVisitCompititors,
  updateVisitCompititors,
  updateVisitStatus,
} from "./services/distributorOrder.services";
import moment from "moment";

const initialState: IDistributorOrderState = {
  loading: false,
  formLoading: false,
  error: "",
  current_date: moment().toDate() || "",
  DistributorList: [],
  DistributorWiseRetailerList: [],
  DistributorOrderID: null,
  CurrentDateWiseDistributor: {},
  FinalOrderDetails: {},
  ItemGroupList: [],
};

const distributorOrderSlice = createSlice({
  name: "distributorOrder",
  initialState,
  reducers: {
    setCurrentDate: (state, action) => {
      state.current_date = action.payload || "";
    },
    setDistributorOrderID: (state, action) => {
      state.DistributorOrderID = action.payload;
    },
    clearDistributorOrderID: (state) => {
      state.DistributorOrderID = initialState.DistributorOrderID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCurrentDateWiseDistributor.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCurrentDateWiseDistributor.fulfilled,
      (state, action: PayloadAction<CurrentDateWiseDistributor>) => {
        state.loading = false;
        state.CurrentDateWiseDistributor = action.payload;
        state.DistributorList = action.payload.distributor_list || [];
      }
    );
    builder.addCase(
      getAllCurrentDateWiseDistributor.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.CurrentDateWiseDistributor =
          initialState.CurrentDateWiseDistributor;
        state.DistributorList = [];
      }
    );

    builder.addCase(
      getAllCurrentDateAndDistributorWiseRetailer.pending,
      (state) => {
        state.loading = true;
        state.error = "";
      }
    );
    builder.addCase(
      getAllCurrentDateAndDistributorWiseRetailer.fulfilled,
      (state, action: PayloadAction<IDistributorWiseRetailerList[]>) => {
        state.loading = false;
        state.DistributorWiseRetailerList = action.payload || [];
      }
    );
    builder.addCase(
      getAllCurrentDateAndDistributorWiseRetailer.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.DistributorWiseRetailerList = [];
      }
    );

    builder.addCase(insertStartDistributorVisit.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(insertStartDistributorVisit.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(insertStartDistributorVisit.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(insertEndDistributorVisit.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(insertEndDistributorVisit.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(insertEndDistributorVisit.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateVisitStatus.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateVisitStatus.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateVisitStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(insertVisitCompititors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(insertVisitCompititors.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(insertVisitCompititors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateVisitCompititors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateVisitCompititors.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateVisitCompititors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getCompititorsBySRRetailerID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getCompititorsBySRRetailerID.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getCompititorsBySRRetailerID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getSelctedInSalesOrderWiseItemGroup.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getSelctedInSalesOrderWiseItemGroup.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.ItemGroupList = action.payload || [];
      }
    );
    builder.addCase(
      getSelctedInSalesOrderWiseItemGroup.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.ItemGroupList = [];
      }
    );

    builder.addCase(getSalesReprsentativePDF.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getSalesReprsentativePDF.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.FinalOrderDetails = action.payload;
      }
    );
    builder.addCase(getSalesReprsentativePDF.rejected, (state, action) => {
      state.loading = false;
      state.FinalOrderDetails = initialState.FinalOrderDetails;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  //   clearDistributorOrderDetails,
  setCurrentDate,
  setDistributorOrderID,
  clearDistributorOrderID,
} = distributorOrderSlice.actions;
export default distributorOrderSlice.reducer;
