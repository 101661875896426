import React from "react";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  // GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
  GridCellProps,
} from "@progress/kendo-react-grid";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import FormDatePicker from "../../components/formFields/FormDateField";
import ButtonForAll from "../../components/common/Button";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  HeaderThElement,
  // PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { openDialog } from "../../components/dialog/dialogSlice";
import { setItemGSTGUID } from "./itemSlice";
import { MdDelete } from "react-icons/md";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { IGst } from "../gst/gstModel";

// interface PageState {
//   skip: number;
//   take: number;
// }

// const initialDataState: PageState = { skip: 0, take: 50 };

const GSTDetailsArray = ({
  formRenderProps,
  setSubmitAction,
  showGST,
  setShowGST,
}: {
  formRenderProps: FormRenderProps;
  setSubmitAction: any;
  showGST: boolean;
  setShowGST: any;
}) => {
  const location = useLocation();
  const item_guid = location.state?.item_guid;
  const ItemDetail = useAppSelector((state) => state.item.ItemDetail);
  const GstList = useAppSelector((state) => state.gst.GstList);
  const dispatch = useAppDispatch();
  const handleClearGST = (formRenderProps: FormRenderProps) => {
    formRenderProps.onChange("item_gst_guid", {
      value: null,
    });
    formRenderProps.onChange("gst_from_date", {
      value: "",
    });
    formRenderProps.onChange("gst_id", {
      value: null,
    });
    setShowGST(!showGST);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 12 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyEditGSTCommandCell = (
    props: GridCellProps,
    formRenderProps: FormRenderProps
  ) => {
    const handleEdit = (item: any) => {
      formRenderProps.onChange("item_gst_guid", {
        value: item?.item_gst_guid,
      });
      formRenderProps.onChange("gst_from_date", {
        value: item?.from_date
          ? moment(item?.from_date, "DD/MM/YYYY").toDate()
          : "",
      });
      formRenderProps.onChange("sub_gst_id", {
        value: item?.gst_id,
      });
      formRenderProps.onChange("gst_end_date", {
        value: item?.to_date
          ? moment(item?.to_date, "DD/MM/YYYY").toDate()
          : "",
      });
    };

    const handleOpenDeleteDialog = (ID: string) => {
      dispatch(openDialog("deleteItemGST"));
      dispatch(setItemGSTGUID(ID));
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem?.item_gst_guid)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  return (
    <>
      {((ItemDetail?.item_gst_details &&
        ItemDetail?.item_gst_details?.length < 1) ||
        showGST ||
        formRenderProps.valueGetter("gst_from_date")) &&
        item_guid && (
          <Card
            style={{
              padding: 10,
              border: "1px solid goldenrod",
              marginBottom: 30,
            }}
          >
            <GridLayout cols={[{ width: "100%" }]}>
              <GridLayoutItem>
                <Typography.h4 className="text-dark">
                  {"GST Details"}
                </Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem
                className="bg-dark text-white fw-600"
                style={{ width: "100%" }}
              >
                <GridLayout
                  style={{
                    marginRight: 20,
                    padding: "10px 0",
                  }}
                  gap={{ rows: 0, cols: "0.5%" }}
                  cols={[{ width: "40%" }, { width: "40%" }, { width: "18%" }]}
                >
                  <GridLayoutItem className="ps-2">From date</GridLayoutItem>
                  <GridLayoutItem className="ps-2">GST (%)</GridLayoutItem>
                  <GridLayoutItem className="ps-2">Actions</GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
              <GridLayoutItem>
                <GridLayout
                  gap={{ rows: 0, cols: "0.5%" }}
                  cols={[{ width: "40%" }, { width: "40%" }, { width: "18%" }]}
                >
                  <GridLayoutItem>
                    <Field
                      name="gst_from_date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="sub_gst_id"
                      placeholder="GST(%)"
                      component={FormSelectionField}
                      options={GstList?.map((gst: IGst) => {
                        return {
                          value: gst?.id,
                          label: gst?.gst_title,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ textAlign: "end", marginTop: 16 }}>
                    <ButtonForAll
                      label={
                        formRenderProps.valueGetter("item_gst_guid")
                          ? "Update"
                          : "Add"
                      }
                      disabled={
                        formRenderProps.valueGetter("gst_from_date") &&
                        formRenderProps.valueGetter("sub_gst_id")
                          ? false
                          : true
                      }
                      type="submit"
                      onClick={() => setSubmitAction("GSTENTRY")}
                    />
                    <Button
                      style={{ marginLeft: 4 }}
                      fillMode={"outline"}
                      themeColor={"primary"}
                      type="button"
                      onClick={() => {
                        handleClearGST(formRenderProps);
                      }}
                    >
                      Clear
                    </Button>
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            </GridLayout>
          </Card>
        )}
      {ItemDetail &&
        ItemDetail?.item_gst_details &&
        ItemDetail?.item_gst_details?.length > 0 && (
          <>
            <GridLayoutItem
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography.h4 className="m-0 text-dark">
                {"GST List"}
              </Typography.h4>
              <ButtonForAll
                label={showGST ? "Hide" : "Add"}
                type="button"
                onClick={() => setShowGST(!showGST)}
              />
            </GridLayoutItem>
            <KendoGrid
              style={{ marginTop: 14 }}
              data={ItemDetail?.item_gst_details}
              total={ItemDetail?.item_gst_details?.length}
              pageable={false}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <Column field="from_date" title="From Date" />
              <Column field="to_date" title="To Date" />
              <Column field="gst_title" title="GST" />
              <Column
                field="ID"
                title="Actions"
                cell={(props: any) =>
                  MyEditGSTCommandCell(props, formRenderProps)
                }
                width={"110px"}
                filterable={false}
                locked={true}
              />
            </KendoGrid>
          </>
        )}
    </>
  );
};

export default GSTDetailsArray;
