import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";
import FormTextArea from "../../components/formFields/FormTextArea";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import { useAppSelector } from "../../app/hooks";
import { IItemGroup } from "../ItemGroup/itemgroupModel";

const AddDistributorCompititorArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const ItemGroupList = useAppSelector(
    (state) => state.distributorOrder.ItemGroupList
  );

  const handleCheckSelected = (e: any, index: number) => {
    if (e?.value === false) {
      fieldArrayRenderProps.formRenderProps.onChange(
        `compititors.${index}.item_group_id`,
        {
          value: [],
        }
      );
      fieldArrayRenderProps.formRenderProps.onChange(
        `compititors.${index}.remarks`,
        {
          value: "",
        }
      );
    }
  };

  return (
    <>
      <table className="distributor-compititor-table mt-3">
        <tr style={{ textAlign: "center", background: "#ffe6e6" }}>
          <th style={{ minWidth: 200, width: "28%" }}>Compititors</th>
          <th style={{ minWidth: 50, width: "16%" }}>Check</th>
          <th style={{ minWidth: 200, width: "28%" }}>Categories</th>
          <th style={{ minWidth: 200, width: "28%" }}>Remarks</th>
        </tr>
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map((compititors: any, index: number) => (
            <tr>
              <td>
                <Field
                  wrapperClassName="w-100"
                  wrapperStyle={{ margin: 0 }}
                  disabled={true}
                  name={`compititors.${index}.compititor_name`}
                  placeholder="Compititor Name"
                  component={FormTextField}
                />
              </td>
              <td>
                <Field
                  wrapperStyle={{
                    width: "17px",
                    margin: "auto",
                  }}
                  name={`compititors.${index}.ischeck`}
                  component={FormCheckbox}
                  labelClassName={"fw-bold"}
                  onChange={(e: any) => handleCheckSelected(e, index)}
                />
              </td>
              <td>
                <Field
                  wrapperStyle={{ margin: 0, width: "100%" }}
                  name={`compititors.${index}.item_group_id`}
                  placeholder="Item Categories"
                  disabled={!fieldArrayRenderProps.value[index]?.ischeck}
                  component={FormMultiSelectionFiled}
                  // validator={requiredValidator}
                  options={ItemGroupList?.map((unit: IItemGroup) => {
                    return {
                      value: unit?.id,
                      label: unit?.item_group,
                    };
                  })}
                />
              </td>
              <td>
                <Field
                  wrapperClassName="w-100 m-0"
                  name={`compititors.${index}.remarks`}
                  placeholder="Remarks"
                  disabled={!fieldArrayRenderProps.value[index]?.ischeck}
                  component={FormTextArea}
                />
              </td>
            </tr>
          ))}
      </table>
      {/* <GridLayout
        cols={[{ width: "100%" }]}
        style={{ overflowX: "scroll", scrollbarWidth: "thin", marginTop: 10 }}
      >
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: "100%" }}
        >
          <GridLayout
            style={{
              marginRight: "0.2%",
              padding: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "28%" },
              { width: "15%" },
              { width: "28%" },
              { width: "28%" },
            ]}
          >
            <GridLayoutItem>Compititors</GridLayoutItem>
            <GridLayoutItem>Check</GridLayoutItem>
            <GridLayoutItem>Categories</GridLayoutItem>
            <GridLayoutItem>Remarks</GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map((compititors: any, index: number) => (
            <GridLayoutItem key={index} style={{ width: "100%" }}>
              <GridLayout
                style={{
                  marginRight: "0.2%",
                  padding: 10,
                  position: "relative",
                }}
                gap={{ rows: 10, cols: 5 }}
                cols={[
                  { width: "28%" },
                  { width: "15%" },
                  { width: "28%" },
                  { width: "28%" },
                ]}
              >
                <GridLayoutItem className="d-flex justify-content-center align-items-center">
                  <Field
                    wrapperClassName="w-100"
                    wrapperStyle={{ margin: 0 }}
                    name={`compititors.${index}.compititor_name`}
                    placeholder="Compititor Name"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem className="d-flex justify-content-center align-items-center">
                  <Field
                    wrapperClassName="m-0"
                    name={`compititors.${index}.ischeck`}
                    component={FormCheckbox}
                    labelClassName={"fw-bold"}
                  />
                </GridLayoutItem>
                <GridLayoutItem className="d-flex justify-content-center align-items-center">
                  <Field
                    wrapperStyle={{ margin: 0, width: "100%" }}
                    name={`compititors.${index}.categories`}
                    placeholder="Item Categories"
                    component={FormMultiSelectionFiled}
                    // validator={requiredValidator}
                    options={ItemGroupList?.map((unit: IItemGroup) => {
                      return {
                        value: unit?.id,
                        label: unit?.item_group,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem className="d-flex justify-content-center align-items-center">
                  <Field
                    wrapperClassName="w-100 m-0"
                    name={`compititors.${index}.remarks`}
                    placeholder="Remarks"
                    component={FormTextArea}
                  />
                </GridLayoutItem>
              </GridLayout>
            </GridLayoutItem>
          ))}
      </GridLayout> */}
    </>
  );
};

export default AddDistributorCompititorArray;
