import React, { useEffect, useRef } from "react";
// import WEBBANNER from "../../assets/Images/webbanner.jpg";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { tokenCheck } from "./services/dashboard.services";
import { LoadingPanel } from "../../components/layout/Loading";
import moment from "moment";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormDatePicker from "../../components/formFields/FormDateField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate } from "react-router-dom";
import {
  getAllCurrentDateWiseDistributor,
  insertEndDistributorVisit,
  insertStartDistributorVisit,
} from "../distributororder/services/distributorOrder.services";
import { setCurrentDate } from "../distributororder/distributorOrderSlice";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DateChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const UserIDString = useAppSelector(
    (state) => state.login.loginDetails.UserID
  );
  const currentDate = useAppSelector(
    (state) => state.distributorOrder.current_date
  );
  const UserID = parseInt(UserIDString);
  const current_date = formRenderProps.valueGetter("current_date");
  const isDateRef = useRef(true);

  useEffect(() => {
    if (isDateRef.current) {
      formRenderProps.onChange("current_date", {
        value: currentDate ? currentDate : moment().toDate(),
      });
      isDateRef.current = false;
    }
  }, [currentDate]);

  useEffect(() => {
    if (current_date && UserID) {
      const payload = {
        current_date: current_date
          ? moment(current_date).format("YYYY-MM-DD")
          : "",
        sr_id: UserID ? +UserID : null,
      };
      dispatch(getAllCurrentDateWiseDistributor(payload));
    }
  }, [current_date, UserID]);

  return null;
};

const SRDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const UserIDString = useAppSelector(
    (state) => state.login.loginDetails.UserID
  );
  const UserID = parseInt(UserIDString);

  const loading = useAppSelector((state) => state.dashboard.loading);
  const distributorOrderLoading = useAppSelector(
    (state) => state.distributorOrder.loading
  );
  const DistributorList = useAppSelector(
    (state) => state.distributorOrder.DistributorList
  );
  const CurrentDateWiseDistributor = useAppSelector(
    (state) => state.distributorOrder.CurrentDateWiseDistributor
  );
  const currentDate = useAppSelector(
    (state) => state.distributorOrder.current_date
  );

  const isVisitDone =
    (DistributorList &&
      DistributorList?.filter((retailer: any) => retailer?.isvisitdone === true)
        ?.length) ||
    0;

  useEffect(() => {
    dispatch(tokenCheck());
  }, []);

  const handleDistributorOrder = (
    distributor_id: number,
    date: string,
    sr_id: number
  ) => {
    navigate("distributororder", {
      state: {
        distributor_id: distributor_id,
        date: date,
        sr_id: sr_id ? +sr_id : null,
      },
    });
  };

  const handleStartButton = async (date: string) => {
    const payload = {
      visit_date: date ? moment(date).format("YYYY-MM-DD") : "",
      sr_id: UserID ? +UserID : null,
      start_time: moment().format("HH:mm"),
    };
    const res = await dispatch(insertStartDistributorVisit(payload));
    if (res.meta.requestStatus === "fulfilled") {
      const payload = {
        current_date: currentDate
          ? moment(currentDate).format("YYYY-MM-DD")
          : "",
        sr_id: UserID ? +UserID : null,
      };
      dispatch(getAllCurrentDateWiseDistributor(payload));
    }
  };
  const handleEndButton = async (date: string) => {
    const payload = {
      visit_date: date ? moment(date).format("YYYY-MM-DD") : "",
      sr_id: UserID ? +UserID : null,
      end_time: moment().format("HH:mm"),
    };
    const res = await dispatch(insertEndDistributorVisit(payload));
    if (res.meta.requestStatus === "fulfilled") {
      const payload = {
        current_date: currentDate
          ? moment(currentDate).format("YYYY-MM-DD")
          : "",
        sr_id: UserID ? +UserID : null,
      };
      dispatch(getAllCurrentDateWiseDistributor(payload));
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {distributorOrderLoading && <LoadingPanel gridRef={gridRef} />}
      <Form
        //   key={formKey}
        //   onSubmit={handleSubmit}
        //   initialValues={DebitNoteDetail}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <FormElement>
              <div className="sr-dashboard container-fluid">
                <div className="container">
                  <div className="row">
                    <div className="col-12 pt-3 pb-3">
                      <h3 className="m-0">Today's Task:</h3>
                      <p className="m-0">
                        Today: {moment().format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <div className="col-3">
                      <Field
                        name="current_date"
                        label="Date for Route Plan"
                        format="dd/MM/yyyy"
                        component={FormDatePicker}
                        validator={requiredValidator}
                        astrike={true}
                        onChange={(e: any) => {
                          dispatch(setCurrentDate(e?.value || ""));
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <hr />
                    </div>
                    {CurrentDateWiseDistributor?.isvisitend ? (
                      <div className="col-12 ">
                        Thank you! All done for today.
                      </div>
                    ) : (
                      <>
                        {DistributorList && DistributorList?.length > 0 && (
                          <div className="col-12 text-end">
                            {!CurrentDateWiseDistributor?.isreached ? (
                              <Button
                                themeColor={"primary"}
                                fillMode={"solid"}
                                type="button"
                                disabled={
                                  CurrentDateWiseDistributor?.isreached ===
                                    true || distributorOrderLoading
                                }
                                onClick={() =>
                                  handleStartButton(
                                    formRenderProps.valueGetter("current_date")
                                  )
                                }
                              >
                                Reached Distributor Office
                              </Button>
                            ) : (
                              <Button
                                themeColor={"primary"}
                                fillMode={"solid"}
                                type="button"
                                disabled={
                                  DistributorList?.length > 0 &&
                                  DistributorList?.length !== isVisitDone
                                }
                                onClick={() =>
                                  handleEndButton(
                                    formRenderProps.valueGetter("current_date")
                                  )
                                }
                              >
                                Left Distributor Office
                              </Button>
                            )}
                          </div>
                        )}
                        <div className="col-12 ">
                          <h6>Today's Distributor List:</h6>
                        </div>
                        <div className="col-12 ">
                          <table className="dist-table">
                            {DistributorList?.map((distribution: any) => (
                              <tr>
                                <td>{distribution?.distributor_name}</td>
                                {CurrentDateWiseDistributor?.isreached && (
                                  <td style={{ display: "flex", gap: 10 }}>
                                    {/* <Button
                                  themeColor={"primary"}
                                  fillMode={"outline"}
                                >
                                  Modify Stock
                                </Button> */}
                                    <Button
                                      themeColor={"primary"}
                                      fillMode={"outline"}
                                      onClick={() =>
                                        handleDistributorOrder(
                                          distribution?.distributor_id,
                                          formRenderProps.valueGetter(
                                            "current_date"
                                          ),
                                          distribution?.sr_id
                                        )
                                      }
                                    >
                                      Walk in Order
                                    </Button>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <DateChangeWatcher formRenderProps={formRenderProps} />
            </FormElement>
          );
        }}
      />
    </>
  );
};

export default SRDashboard;
